import { initializeApp } from 'firebase/app';

import { env } from '@vette/common-utils';

const { checkEnvVarValue } = env;

const measurementId = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;

export const app = initializeApp({
  appId: checkEnvVarValue(process.env.NEXT_PUBLIC_FIREBASE_APP_ID),
  apiKey: checkEnvVarValue(process.env.NEXT_PUBLIC_FIREBASE_API_KEY),
  authDomain: checkEnvVarValue(process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN),
  databaseURL: checkEnvVarValue(process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL),
  projectId: checkEnvVarValue(process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID),
  storageBucket: checkEnvVarValue(
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET
  ),
  messagingSenderId: checkEnvVarValue(
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_ID
  ),
  ...(measurementId ? { measurementId } : {}),
});
