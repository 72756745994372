import styled, { css } from 'styled-components';
import { Space as AntSpace } from 'antd';

type AntSpaceAdditionalProps = {
  $centerVertically?: boolean;
  $fullWidth?: boolean;
  $padding?: string;
};

export const Space = styled(AntSpace)<AntSpaceAdditionalProps>`
  ${({ $fullWidth }) => {
    return (
      $fullWidth &&
      css`
        width: 100%;
      `
    );
  }}
  ${({ $padding }) => {
    return (
      $padding &&
      css`
        padding: ${$padding};
      `
    );
  }}

  & > .ant-space-item {
    ${({ $fullWidth }) => {
      return (
        $fullWidth &&
        css`
          width: 100%;
        `
      );
    }}

    ${({ $centerVertically }) => {
      return (
        $centerVertically &&
        css`
          display: flex;
          justify-content: center;
        `
      );
    }}
  }
`;
