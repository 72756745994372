import * as Sentry from '@sentry/nextjs';
import { useCallback, useEffect, useState } from 'react';
import {
  connectDatabaseEmulator,
  get,
  getDatabase,
  onValue,
  ref,
  remove as firebaseRemove,
} from 'firebase/database';
import { app } from './app';

const database = getDatabase(app);

if (
  typeof window !== 'undefined' &&
  process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST &&
  process.env.NEXT_PUBLIC_FIREBASE_DATABASE_EMULATOR_HOST
) {
  console.log('You are now logged to firebase database emulator');
  const [host, port] =
    process.env.NEXT_PUBLIC_FIREBASE_DATABASE_EMULATOR_HOST.split(':');
  connectDatabaseEmulator(database, host, parseInt(port));
}

export const useWatchRef = <TValue>(
  path?: string,
  onChange?: (value?: TValue) => Promise<void> | void
) => {
  useEffect(() => {
    if (!path) {
      Sentry.addBreadcrumb({
        type: 'sessionWatchRef',
        message: 'Path is not defined',
        level: 'warning',
      });
      onChange?.(undefined);
      return;
    }
    return onValue(ref(database, path), async snapshot => {
      const value = snapshot.val();
      Sentry.addBreadcrumb({
        type: 'sessionWatchRef',
        message: `Value changed for path: ${path}`,
        level: 'info',
        data: {
          value,
          path,
        },
      });
      await onChange?.(value);
    });
  }, [path, onChange]);
};

type Loading = boolean;
export const useWatchRefState = <TValue>(
  path?: string
): [TValue | undefined, Loading] => {
  const [loading, setLoading] = useState<boolean>(true);
  const [value, setValue] = useState<TValue>();

  useWatchRef<TValue>(
    path,
    useCallback(
      (value?: TValue) => {
        setValue(value);
        setLoading(false);
      },
      [setValue, setLoading]
    )
  );

  return [value, loading];
};

export const getValue = async <TValue>(path: string) => {
  const snapshot = await get(ref(database, path));
  return snapshot.val() as TValue;
};

export const remove = async (path: string) => {
  return firebaseRemove(ref(database, path));
};
