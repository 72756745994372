import React, { createContext, useContext } from 'react';

import { useAuth } from './state';

type FirebaseAuthContext = ReturnType<typeof useAuth>;

export const Context = createContext<FirebaseAuthContext>(
  {} as unknown as FirebaseAuthContext
);

export const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Context.Provider value={useAuth()}>{children}</Context.Provider>;
};

export const useFirebase = () => useContext(Context);
