import React from 'react';
import { Typography as AntTypography } from 'antd';
import styled, { css } from 'styled-components';

const {
  Title: AntTitle,
  Text: AntText,
  Paragraph: AntParagraph,
} = AntTypography;

export const Title = styled(AntTitle)<{
  $marginBottom?: number;
  $marginTop?: number;
  $size?: number;
  $color?: string;
  $disabled?: boolean;
  $align?: 'left' | 'center' | 'right';
}>`
  ${({ $marginBottom }) =>
    $marginBottom &&
    css`
      margin-bottom: ${$marginBottom}px !important;
    `};
  ${({ $marginTop }) =>
    $marginTop &&
    css`
      margin-top: ${$marginTop}px !important;
    `};
  && {
    ${({ $size }) =>
      $size &&
      css`
        font-size: ${$size}px;
      `};
    ${({ $color }) =>
      $color &&
      css`
        color: ${$color};
      `};

    ${({ $align }) =>
      $align &&
      css`
        text-align: ${$align};
      `};

    ${({ $disabled, theme }) =>
      $disabled &&
      css`
        color: ${theme.colors.grey40};
      `}
  }
`;

type TextProps = React.PropsWithChildren<{
  $size?: number;
  $lineHeight?: number;
  $color?: string;
  $weight?: number;
  $marginBottom?: number;
  $disabled?: boolean;
  $centered?: boolean;
  $noWrap?: boolean;
  $noUserSelect?: boolean;
}>;

// TODO: refactor me :(
export const Text = styled(AntText)<TextProps>`
  font-weight: ${({ $weight }) => ($weight ? $weight : 400)};
  ${({ $marginBottom }) =>
    $marginBottom &&
    css`
      margin-bottom: ${$marginBottom}px !important;
    `};
  ${({ $size }) =>
    $size &&
    css`
      font-size: ${$size}px;
    `};

  ${({ $lineHeight, $size }) =>
    $lineHeight
      ? css`
          line-height: ${$lineHeight}px;
        `
      : $size &&
        css`
          line-height: ${$size}px;
        `};

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `};
  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      color: ${theme.colors.grey40};
    `};
  ${({ $centered }) =>
    $centered &&
    css`
      display: block;
      text-align: center;
    `};
  ${({ $noWrap }) =>
    $noWrap &&
    css`
      white-space: nowrap;
    `};
  ${({ $noUserSelect }) =>
    $noUserSelect &&
    css`
      user-select: none;
    `};

  div.ant-typography-copy {
    margin-left: 12px;
  }
`;

type ParagraphProps = TextProps & {
  $align?: 'left' | 'center' | 'right';
  $marginTop?: number;
};

export const Paragraph = styled(AntParagraph)<ParagraphProps>`
  font-weight: ${({ $weight }) => ($weight ? $weight : 400)};
  ${({ $marginBottom }) =>
    $marginBottom &&
    css`
      margin-bottom: ${$marginBottom}px !important;
    `};
  ${({ $marginTop }) =>
    $marginTop &&
    css`
      margin-top: ${$marginTop}px !important;
    `};
  ${({ $size }) =>
    $size &&
    css`
      font-size: ${$size}px;
    `};
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `};
  ${({ $align }) =>
    $align &&
    css`
      text-align: ${$align};
    `};

  & > a.ant-typography-expand {
    color: ${({ theme }) => theme.colors.blue60};
  }
`;
