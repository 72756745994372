import React, { useEffect, useMemo, useState } from 'react';
import { firebase as firebaseService } from '../..';
import { firebase as firebaseEntity } from '@vette/common-utils';
import { usePreviousDistinct } from 'react-use';

type Props = {
  fallback?: React.ReactNode;
  userId?: string;
  children: (
    userId: string,
    sessionId?: string,
    session?: firebaseEntity.Session
  ) => React.ReactNode;
};

export const VetterSession: React.FC<Props> = ({
  userId,
  children,
  fallback,
}) => {
  const [sessionFromPushNotification, setSessionFromPushNotification] =
    useState<firebaseEntity.Session & { id: string }>();
  const { user } = firebaseService.useFirebase();

  const [observedSessionId, vettersWatchLoading] =
    firebaseService.database.useWatchRefState<string>(
      user && user.uid && `vetters/${user.uid}/sessionId`
    );

  // For some reason internet edge on Windows looses a connection to realtime db and makes it appear the value is undefined
  // https://vette-inc.sentry.io/issues/4844929729/events/54ea1c28beed4c39b8182e08143a2ce3/?project=6100004&referrer=event-or-group-header&statsPeriod=7d
  // we need to patch it and try to recover the sessionId
  const previousObservedSessionId = usePreviousDistinct(
    observedSessionId,
    (prev, next) => !!next && prev !== next && !vettersWatchLoading
  );
  const sessionId: string | undefined = useMemo(() => {
    return (
      observedSessionId ||
      previousObservedSessionId ||
      sessionFromPushNotification?.id
    );
  }, [
    observedSessionId,
    previousObservedSessionId,
    sessionFromPushNotification,
  ]);

  const [session, sessionsWatchLoading] =
    firebaseService.database.useWatchRefState<firebaseEntity.Session>(
      sessionId && `sessions/${sessionId}`
    );

  useEffect(() => {
    return firebaseService.messaging.subscribeToPushNotification(event => {
      if (event.data.type === 'new-vette-notification' && event.data.id)
        setSessionFromPushNotification({
          ...event.data,
          statusTimeout: Number(event.data.statusTimeout),
        });
    });
  }, []);

  if (!userId) {
    return <>{fallback || <div />}</>;
  }

  return (
    <>
      {!vettersWatchLoading &&
        !sessionsWatchLoading &&
        children(userId, sessionId, session || sessionFromPushNotification)}
    </>
  );
};
