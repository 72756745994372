import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { pageview } from '../gtag';

type Props = {
  measurementId: string;
};

export const RouteTracker = ({ measurementId }: Props) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: URL) => pageview(url, measurementId);
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [measurementId, router.events]);

  return null;
};
