/* eslint-disable @typescript-eslint/no-explicit-any */
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages

export const pageview = (url: URL, measurementId: string): void => {
  if (
    typeof window !== 'undefined' &&
    typeof (window as any).gtag !== 'undefined'
  )
    (window as any).gtag('config', measurementId, {
      page_path: url,
    });
};

type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent): void => {
  if (
    typeof window !== 'undefined' &&
    typeof (window as any).gtag !== 'undefined'
  )
    (window as any).gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
};
