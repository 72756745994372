import styled from 'styled-components';
import { theme } from '../../theme';

export const Wrapper = styled.div`
  align-items: flex-start;
  border: 1px solid;
  border-color: ${theme.colors.grey20};
  border-radius: 8px;
  display: flex;
  gap: 24px;
  justify-content: center;
  padding: 24px 0px;
  position: relative;
`;

export const Section = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.span`
  font-weight: 600;
`;

export const Content = styled.span``;

export const WrapperX = styled.div`
  .frame {
    align-items: flex-start;
    border: 1px solid;
    border-color: var(--grey-grey-20);
    border-radius: 8px;
    display: flex;
    gap: 24px;
    justify-content: center;
    padding: 24px 0px;
    position: relative;
  }

  .frame .div {
    color: var(--greygray-100);
    flex: 1;
    font-family: 'Poppins-SemiBold', Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.6px;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }

  .frame .text-wrapper {
    font-weight: 600;
  }

  .frame .span {
    font-family: 'Poppins-Regular', Helvetica;
  }

  .frame .text-wrapper-2 {
    font-family: var(--t2-font-family);
    font-size: var(--t2-font-size);
    font-style: var(--t2-font-style);
    font-weight: var(--t2-font-weight);
    letter-spacing: var(--t2-letter-spacing);
    line-height: var(--t2-line-height);
  }
`;
