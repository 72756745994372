import { Button as BaseButton, Modal as BaseModal } from '../atoms';
import styled from 'styled-components';

export const Modal = styled(BaseModal)``;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
`;

export const Button = styled(BaseButton)`
  min-width: 148px;
`;
