import styled from 'styled-components';
import { SimpleModal } from '../Modal';

export const Modal = styled(SimpleModal)<{ $reverseButtons?: boolean }>`
  & .ant-modal-content {
    padding: 40px !important;
  }

  & .ant-btn {
    height: 48px;
    ${({ $reverseButtons }) =>
      $reverseButtons ? `margin-left` : `margin-right`}: 8px;
  }

  & .ant-modal-footer {
    flex-direction: ${({ $reverseButtons }) =>
      $reverseButtons ? 'row-reverse' : null};
  }
`;
