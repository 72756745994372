import * as Sentry from '@sentry/nextjs';
import { env, sentryBeforeSend } from '@vette/common-utils';

export const setup = (applicationName: string) => {
  Sentry.init({
    dsn: env.checkEnvVarValue(process.env.NEXT_PUBLIC_SENTRY_DSN),
    tracesSampleRate: 1,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
    tunnel: undefined,
    integrations: [
      ...(typeof window !== 'undefined' ? [new Sentry.Replay()] : []),
    ],
    beforeSend: sentryBeforeSend,
  });

  Sentry.setTag('application', applicationName);
};
