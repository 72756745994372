import React from 'react';
import { firebase } from '@vette/frontend-utils';
import { NewSessionWatcher } from './NewSessionWatcher';
import { SessionEndWatcher } from './SessionEndWatcher';
import { SessionInProgressWatcher } from './SessionInProgressWatcher';
import { SessionMissedWatcher } from './SessionMissedWatcher';

type Props = {
  userId: string;
};

export const SessionWatchers: React.FC<Props> = ({ userId }) => {
  return (
    <firebase.VetterSession userId={userId}>
      {(userId, sessionId, session) => (
        <>
          <NewSessionWatcher
            userId={userId}
            sessionId={sessionId}
            session={session}
          />
          <SessionMissedWatcher
            userId={userId}
            sessionId={sessionId}
            session={session}
          />
          <SessionInProgressWatcher
            userId={userId}
            sessionId={sessionId}
            session={session}
          />
          <SessionEndWatcher
            userId={userId}
            sessionId={sessionId}
            session={session}
          />
        </>
      )}
    </firebase.VetterSession>
  );
};
