import { jobs } from '../domain';
export const ROUTES = {
  client: {
    '404': '/404',
    applicants: '/applicants',
    dashboard: '/dashboard',
    scripts: '/scripts',
    jobs: '/jobs',
    jobs_return_after_create: ({
      parentGroupId,
      viewType,
    }: {
      parentGroupId: string;
      viewType: jobs.ViewType;
    }) => `/jobs?view=${viewType}#${parentGroupId}`,
    scripts_create: '/scripts/create',
    jobs_create: ({
      parentGroupId,
      viewType,
    }: {
      parentGroupId: string;
      viewType: jobs.ViewType;
    }) => `/jobs/create?parentGroupId=${parentGroupId}&viewType=${viewType}`,
    scripts_edit: ({ scriptId }: { scriptId: string }) =>
      `/scripts/edit/${scriptId}`,
    sessions_view: ({ sessionId }: { sessionId: string }) =>
      `/sessions/view/${sessionId}`,
    jobs_edit: ({ jobOfferId }: { jobOfferId: string }) =>
      `/jobs/edit/${jobOfferId}`,
    login: '/login',
    team_members: '/team-members',
    invitation: '/invitation',
  },
  admin: {
    '404': '/404',
    activity: '/activity',
    dashboard: '/dashboard',
    login: '/login',
    sms: '/sms',
    clients: '/clients',
    dispatching: '/dispatching',
    vetters: '/vetters',
    clients_manage: ({ clientId }: { clientId: string }) =>
      `clients/manage/${clientId}/dashboard`,
    clients_ledger: ({ clientId }: { clientId: string }) =>
      `/clients/ledger/${clientId}/list`,
    clients_details: ({ clientId }: { clientId: string }) =>
      `/clients/details/${clientId}`,
    clients_applicant_details: ({
      clientId,
      sessionId,
    }: {
      clientId?: string;
      sessionId: string;
    }) => `/clients/manage/${clientId}/sessions/view/${sessionId}`,
    clients_team_members: ({ clientId }: { clientId: string }) =>
      `/clients/manage/${clientId}/team-members`,
  },
  applicant: {
    '404': '/404',
    privacy: '/privacy',
    terms: '/terms',
  },
  vetter: {
    '404': '/404',
    dashboard: '/dashboard',
    login: '/login',
    signup: '/signup',
    terms: '/terms',
    interview: ({ sessionId }: { sessionId: string }) =>
      `/interview/${sessionId}`,
    settings: '/settings',
    invitation: (key?: string | null) =>
      key ? `/invitation?key=${key}` : '/invitation',
  },
};
