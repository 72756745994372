import {
  Experiment,
  GrowthBook as BaseGrowthBook,
  Result,
  Context,
} from '@growthbook/growthbook-react';
import { GrowthBookCache } from './GrowthBookCache';

type Subscriber = (experiment: Experiment<any>, result: Result<any>) => void;

export class GrowthBook extends BaseGrowthBook {
  private _subscribers: Subscriber[] = [];
  private cache = new GrowthBookCache();

  constructor(options: Context) {
    super({
      ...options,
      trackingCallback: (experiment, result) => {
        this._subscribers.forEach(s => s(experiment, result));
        options.trackingCallback?.(experiment, result);
      },
    });

    this.cache.loadFeatures().then(features => {
      this.setFeatures(features);
      this.cache.subscribe(features => this.setFeatures(features));
    });
  }

  public appendAttributes(attributes: Record<string, any>) {
    this.setAttributes({
      ...this.getAttributes(),
      ...attributes,
    });
  }

  public subscribeToExperimentVariations(subscriber: Subscriber) {
    this._subscribers.push(subscriber);
    return () => {
      this._subscribers = this._subscribers.filter(s => s !== subscriber);
    };
  }
}
