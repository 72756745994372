import styled from 'styled-components';
import { Modal as AntModal } from 'antd';

export const Modal = styled(AntModal)<{ $footerJustify?: string }>`
  border-radius: 16px;

  .ant-modal-content {
    padding: 40px;
  }

  .ant-modal-header {
    border: 0;
    padding: 14px 24px;
  }

  .ant-modal-title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
  }

  .ant-modal-body {
    text-align: center;
    padding: 10px 24px;
  }

  .ant-modal-footer {
    border: 0;
    padding: 14px 24px;
    display: flex;
    align-items: center;
    justify-content: ${({ $footerJustify }) => $footerJustify ?? 'center'};
  }
`;

export const SimpleModal = styled(AntModal)<{
  $footerJustify?: string;
  width?: string | number;
  $padding?: {
    vertical: number;
    horizontal: number;
  };
}>`
  border-radius: 18px;
  width: ${({ width }) => (width ? `${width}px` : '50%')} !important;

  .ant-modal-content {
    padding: ${({ $padding }) =>
      $padding
        ? `${$padding.vertical}px ${$padding.horizontal}px`
        : '48px 72px'};
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
  }

  .ant-modal-body {
    text-align: left;
    padding: 0;
  }

  .ant-modal-footer {
    border: 0;
    padding: 24px 0 0;
    display: flex;
    align-items: center;
    justify-content: ${({ $footerJustify }) => $footerJustify ?? 'center'};
  }
`;
