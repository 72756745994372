import React from 'react';

import * as S from './styles';
import { ModalProps } from 'antd';
import { Button } from '../Button';

type Props = {
  footerJustify?:
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'flex-end'
    | 'flex-start';
  padding?: { vertical: number; horizontal: number };
} & ModalProps;

export const Modal: React.FC<Props> = ({
  children,
  footerJustify,
  padding,
  ...props
}) => {
  return (
    <S.Modal
      footer={
        props.footer || (
          <>
            <Button
              {...props.cancelButtonProps}
              type="default"
              onClick={props.onCancel}
            >
              {props.cancelText || 'Cancel'}
            </Button>
            <Button
              {...props.okButtonProps}
              type="primary"
              onClick={props.onOk}
            >
              {props.okText || 'Ok'}
            </Button>
          </>
        )
      }
      $footerJustify={footerJustify}
      {...props}
    >
      {children}
    </S.Modal>
  );
};

export const SimpleModal: React.FC<Props> = ({
  children,
  footerJustify,
  padding,
  footer = null,
  centered = true,
  closable = false,
  ...props
}) => {
  return (
    <S.SimpleModal
      {...props}
      footer={footer}
      $padding={padding}
      $footerJustify={footerJustify}
      centered={centered}
      closable={closable}
    >
      {children}
    </S.SimpleModal>
  );
};
