import { useApolloClient } from '@apollo/client';
import { firebase as firebaseEntity } from '@vette/common-utils';
import {
  User,
  VetterDebugLogEventsEnum,
  UserPresenceEnum,
  SessionStatusEnum,
} from '@vette/data-access';
import { MissedSessionModal } from '@vette/ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useVetterLog } from '../hooks/useVetterLog';

export const SessionMissedWatcher: React.FC<{
  userId: string;
  sessionId?: string;
  session?: firebaseEntity.Session;
}> = ({ userId, sessionId, session }) => {
  const [hadNewSession, setHadNewSession] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const log = useVetterLog();

  const client = useApolloClient();

  useEffect(() => {
    const showMissedSession = () => {
      log({
        userId,
        globalActivityHistoryEvent:
          VetterDebugLogEventsEnum.VETTER_SAW_SESSION_REQUEST_TIMED_OUT_MODAL,
        metadata: {
          sessionId,
          session,
        },
      });
      setShowModal(true);
    };

    if (!sessionId || !session) {
      if (hadNewSession) {
        showMissedSession();
      }
    } else {
      switch (session.status) {
        case SessionStatusEnum.WAITING_FOR_VETTER_TO_ACCEPT_SESSION_REQUEST:
          setHadNewSession(true);
          break;
        case SessionStatusEnum.VETTER_REJECTED_SESSION_REQUEST:
        case SessionStatusEnum.VETTER_REQUEST_TIMED_OUT:
          showMissedSession();
          client.cache.modify({
            id: client.cache.identify({ __typename: 'User', id: userId }),
            fields: {
              presence: () => UserPresenceEnum.OFFLINE,
            } as Partial<{
              [K in keyof User]: (value: User[K]) => User[K];
            }>,
          });
          break;
        default:
          setHadNewSession(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, sessionId, session, client]);

  const missedSessionHandled = useCallback(() => {
    setHadNewSession(false);
    setShowModal(false);
  }, []);

  return (
    <>
      {userId && (
        <MissedSessionModal
          userId={userId}
          visible={showModal}
          onClose={missedSessionHandled}
        />
      )}
    </>
  );
};
