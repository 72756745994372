import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { auth } from './initializeAuth';

type StatelessCondition = (
  route: string,
  isLoggedIn: boolean
) => string | undefined | false;
type StatefulCondition = (route: string) => string | undefined | false;

type Props = React.PropsWithChildren<{
  /**
   * Takes a route and flag whether user is logged in
   * Based on that determines where the user should be redirected
   * If no redirect is required returns undefined
   *
   * @example
   * ```ts
   *  <RedirectGuard conditions=[
   *  (route, isLoggedIn) => route === '/login' && isLoggedIn && '/dashboard'
   * ]><Component /></RedirectGuard>
   *
   *  <RedirectGuard loggedInRedirects=[
   *    (route) => route === '/login' && '/dashboard'
   *  ]><Component /></RedirectGuard>
   *
   *  <RedirectGuard loggedOutRedirects=[
   *    (route) => !['login', 'signup'].includes(route) && '/login'
   *  ]><Component /></RedirectGuard>
   * ```
   */
  conditions?: StatelessCondition[];
  loggedInRedirects?: StatefulCondition[];
  loggedOutRedirects?: StatefulCondition[];
}>;

export const RedirectGuard: React.FC<Props> = ({
  conditions = [],
  loggedInRedirects = [],
  loggedOutRedirects = [],
  children,
}) => {
  const router = useRouter();

  useEffect(() => {
    if (router.route === '/_error') return;
    return auth.onAuthStateChanged(userOrNull => {
      const isLoggedIn = !!userOrNull;

      for (const condition of conditions ?? []) {
        const newRoute = condition(router.route, isLoggedIn);
        if (newRoute) {
          router.replace(newRoute);
          return;
        }
      }

      for (const loggedInCondition of loggedInRedirects) {
        const newRoute = loggedInCondition(router.route);
        if (isLoggedIn && newRoute) {
          router.replace(newRoute);
          return;
        }
      }

      for (const loggedOutCondition of loggedOutRedirects) {
        const newRoute = loggedOutCondition(router.route);
        const getQuery = () => {
          if (['/404'].includes(router.route)) return {};
          return {
            query: { redirect: router.asPath },
          };
        };
        if (!isLoggedIn && newRoute) {
          router.replace({
            pathname: newRoute,
            ...getQuery(),
          });
        }
      }
    });
  }, [router, conditions, loggedInRedirects, loggedOutRedirects]);

  return <>{children}</>;
};
