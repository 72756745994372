import React, { useContext } from 'react';
import {
  ADMIN_MENU,
  APPLICANT_MENU,
  CLIENT_MENU,
  SideMenuType,
  VETTER_MENU,
} from './sideMenu';

type AppContextType = {
  app: 'Admin' | 'Applicant' | 'Vetter' | 'Client';
  sideMenu: SideMenuType;
};

export const APP_CONTEXT_OPTIONS = {
  admin: {
    app: 'Admin' as const,
    sideMenu: ADMIN_MENU,
  },
  adminAsClient: {
    app: 'Admin' as const,
    sideMenu: CLIENT_MENU,
  },
  applicant: {
    app: 'Applicant' as const,
    sideMenu: APPLICANT_MENU,
  },
  client: {
    app: 'Client' as const,
    sideMenu: CLIENT_MENU,
  },
  vetter: {
    app: 'Vetter' as const,
    sideMenu: VETTER_MENU,
  },
};

export const AppContext = React.createContext<AppContextType | undefined>(
  undefined
);

export const useAppContext = (): AppContextType => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error(
      'App context is not available. Did you forget to wrap your application with ApplicationContext.Provider?'
    );
  }

  return appContext;
};
