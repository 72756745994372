// eslint-disable-next-line no-restricted-imports
import { message as antdMessage } from 'antd';
import { event } from '../hotjar';

/**
 * Proxy to intercept error messages and invoke the "Saw Error" hotjar event.
 */
export const message = new Proxy(antdMessage, {
  get(target, prop, receiver) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const originalMethod: any = Reflect.get(target, prop, receiver);

    if (prop === 'error') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (...args: any[]) => {
        event('Saw Error');
        return originalMethod.apply(target, args);
      };
    }

    return originalMethod;
  },
}) as typeof antdMessage;
