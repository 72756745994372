import router, { useRouter } from 'next/router';
import { useCallback } from 'react';
import { ROUTES } from './routes';

// There could be a more reliable solution, if for some reason client enters this path, it might cause some unexpected behaviour
export const isAdminAsClient = (path: string) =>
  path.startsWith('/clients/manage');

export const removeAdminPath = (path: string) => {
  if (isAdminAsClient(path)) {
    const pathParts = path.split('/');
    return '/' + pathParts.slice(4, pathParts.length).join('/');
  }
  return path;
};

export const useCustomRouter = () => {
  const router = useRouter();

  const prefix = isAdminAsClient(router.asPath)
    ? router.asPath.split('/').slice(0, 4).join('/')
    : '';

  return {
    router,
    getPath: useCallback((path: string) => prefix + path, [prefix]),
    pages: ROUTES,
  };
};

export const use404Redirect = (typeName?: string, id?: string | null) => {
  if (typeName === 'NotFoundError' || !id) {
    return router.replace('/404');
  }
  return;
};
