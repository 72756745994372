import { isSuperset, normalize, simplify } from 'scopeutils';

const realms = ['vette'] as const;
const resources = ['team'] as const;
const actions = ['view', 'invite', 'remove'] as const;

export type Realm = (typeof realms)[number];
export type Resource = (typeof resources)[number];
export type Action = (typeof actions)[number];

export type AccessPermission = `${Realm}:${Resource}:${Action}`;

export const permissions = {
  team: {
    view: 'vette:team:view',
    invite: 'vette:team:invite',
    remove: 'vette:team:remove',
  },
} satisfies Record<Resource, Record<Action, AccessPermission>>;

export const hasAccess = (
  currentAccess: AccessPermission[] | undefined | null,
  requiredAccess: AccessPermission[] | AccessPermission
) => (currentAccess ? isSuperset(currentAccess, requiredAccess) : false);

export const simplifyAccess = (access: AccessPermission[]) =>
  simplify(access).map(normalize);
