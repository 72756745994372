export enum GrowthBookFeatures {
  // backend:
  engaged_smses = 'engaged_smses',
  engaged_email_body = 'engaged_email_body',
  engaged_email_subject = 'engaged_email_subject',
  reengaged_email_subject = 'reengaged_email_subject',
  reengage_email_body = 'reengage_email_body',
  hours_to_reengage_emails = 'hours_to_reengage_emails',
  reengage_sms = 'reengage_sms',
  reengage_missed_sms = 'reengage_missed_sms',
  feedback_smses = 'feedback_smses',
  touch_point_smses = 'touch_point_smses',
  reported_issue_sms = 'reported_issue_sms',
  looking_for_vetter_timed_out_sms = 'looking_for_vetter_timed_out_sms',
  applicant_unreachable_sms = 'applicant_unreachable_sms',
  engaged_applicant_via_email = 'engaged_applicant_via_email',
  default_user_permission = 'default_user_permission',
  applicant_unreachable_failed_sms = 'applicant_unreachable_failed_sms',
  reengage_smses = 'reengage_smses',
  hours_to_reengage_smses = 'hours_to_reengage_smses',
  ai_auto_reply = 'ai_auto_reply',
  ai_sms_context = 'ai_sms_context',
  ai_auto_email = 'ai_auto_email',
  ai_email_context = 'ai_email_context',
  ai_summary = 'ai_summary',
  ai_summary_context = 'ai_summary_context',
  ai_question_answer_context = 'ai_question_answer_context',
  summarization_type = 'summarization_type',
  touch_point_sms = 'touch_point_sms',
  // frontend:
  interview_now_page_configuration = 'interview_now_page_configuration',
  interview_script_form = 'interview_script_form',
}

export type SummarizationType = 'meeting_summary' | 'question_answer';
export const growthbookFeaturesDefault = {
  [GrowthBookFeatures.ai_auto_reply]: false,
  [GrowthBookFeatures.ai_summary]: false,
  [GrowthBookFeatures.ai_auto_email]: false,
  // Currently available ones: meeting_summary,question_answer
  [GrowthBookFeatures.summarization_type]: 'meeting_summary',
  [GrowthBookFeatures.engaged_smses]: `{{flag#main}}Hey {{first_name}}! This is Amber, I saw that you applied to the {{job_name}} role with {{client}}. We have an interviewer available, would you be interested in phone interviewing now?{{flag#replied}}Awesome! Whenever you are ready to interview just click the link below to put your information in and someone will call you shortly thereafter.{{break}}{{interview_now_link}}{{break}}If you have any questions at all let me know 😊`,
  [GrowthBookFeatures.reengage_smses]: `["Hey {{first_name}}, is the {{job_name}} for {{client}} still something you’re interested in? If so we have someone who can interview you 🙂 Would you like to move forward in the interview process?"]`,
  [GrowthBookFeatures.reengage_email_body]: `["Hey {{first_name}}, is the {{job_name}} for {{client}} still something you’re interested in? If so we have someone who can interview you 🙂"]`,
  [GrowthBookFeatures.reengaged_email_subject]: `["Ready to start your interview?"]`,
  [GrowthBookFeatures.engaged_email_subject]: 'Ready to start your interview?',
  [GrowthBookFeatures.engaged_email_body]: `Hi there {{first_name}}, this is Amber reaching out on behalf of {{client}}. I saw that you applied to the {{job_name}} role. \nWe have someone who can interview you now or when you're ready if you'd like. Just click the link below and someone will contact you shortly thereafter. \n\n{{interview_now_link}}\n\nLet me know if you have any questions or need anything else 🙂`,
  [GrowthBookFeatures.touch_point_sms]: true,
  [GrowthBookFeatures.default_user_permission]: {
    ADMIN: ['vette:**:*'],
    CLIENT: { INTERVIEW: [], MANAGE: ['vette:team:*'] },
  },
} as const;

export const defaultInterviewScriptFormFeatureValue = {
  acceptRequestModal: { displayJob: false },
  jobDetails: {
    labels: {
      applicant: 'Applicant',
      clientName: 'Company',
      job: 'Job',
      salary: 'Compensation',
      description: 'Job Description',
      additionalDetails: 'Additional Details',
    },
  },
  script: {
    heading: 'Interview Questions',
    pressDigit1Prompt: 'Press digit 1 on your phone to start the interview',
    confirmApplicantPickedUpButton: 'Successfully speaking to applicant',
    confirmationApplicantDidNotPickUp:
      'Are you sure you got the applicant’s voicemail?',
    introductionLine:
      "“Awesome! My name is {{vetter_first_name}} and I’ll be interviewing you for the {{job}} role for {{client}} {{#location}}in {{location}}{{/location}}that you applied to. How are you doing today?{{break}}Just so you're aware, this call will be recorded to ensure the information you offer is accurately captured and conveyed.{{break}}So if you are ready we can go ahead and get started.”",
    closingLine:
      '“Alright, well that concludes the interview. It was great speaking with you. Please keep an eye out for two text messages. The first will be rating your interview experience, and the second will be a text message about next steps.”',
    endCallInstruction: 'Hang up the phone with applicant.',
  },
  feedback: {
    heading: 'Hiring Recommendation',
    title: 'Would you recommend the applicant for this job?',
    reasonForRecommendationInputOnYes: false,
    reasonForRecommendationInputOnNo: false,
  },
  complete: {
    title: 'Interview Complete',
    possitiveAffirmations: [
      '“Be the change you wish to see in the world – Mahatma Gandhi“',
      '“We rise by lifting others – Robert Ingersoll“',
      '“One person can make a difference, and everyone should try - JFK“',
      '“It is in your hands, to make a better world for all who live in it - Nelson Mandela“',
    ],
  },
  displayNotes: false,
};

export const defaultInterviewNowPageFeatureValue = {
  jobDetail: { customHeadline: false, zipCodeInput: false },
  form: {
    headline:
      'Fill out the form below to be connected with a live human for your {{flag#blue}} instant interview',
    interviewNowButton: 'Interview Now',
    interviewNowButtonLookingForVetter:
      'Hang tight, get comfortable while we search for your interviewer…',
    disableSpanishInterview: true,
  },
};
