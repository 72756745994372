import crossFetch from 'cross-fetch';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  defaultDataIdFromObject,
  from,
  split,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { env } from '@vette/common-utils';
import authLink from './authLink';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { auth } from '../firebase';
import { errorLink } from './errorLink';

const WS_URI = env.checkEnvVarValue(process.env.NEXT_PUBLIC_GRAPHQL_WS);

const httpLink = createUploadLink({
  uri: env.checkEnvVarValue(process.env.NEXT_PUBLIC_GRAPHQL_URL),
  credentials: 'same-origin',
  fetch: crossFetch,
  headers: {
    'x-apollo-operation-name': 'ApolloOperationQuery',
    'apollo-require-preflight': 'true',
  },
});

const retryLink = new RetryLink();

const getLink = () => {
  const isServer = typeof window === 'undefined';

  const wsLink = !isServer
    ? new GraphQLWsLink(
        createClient({
          url: WS_URI,
          lazy: true,
          connectionParams: async () => {
            const token = await auth.currentUser?.getIdToken();
            return {
              authorization: token,
            };
          },
        })
      )
    : null;

  const allLinksChain = from([errorLink, authLink, retryLink, httpLink]);

  return !isServer && wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        allLinksChain
      )
    : allLinksChain;
};

const isServer = typeof window === 'undefined';

// Next fills window with __NEXT_DATA__
const windowInNext = !isServer
  ? (window as unknown as {
      __NEXT_DATA__?: { apolloState: NormalizedCacheObject };
    })
  : undefined;

const windowApolloState = !isServer && windowInNext?.__NEXT_DATA__?.apolloState;

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export function resetApolloLink() {
  if (apolloClient) {
    apolloClient.setLink(getLink());
  }
}

export function getApolloClient(forceNew = false) {
  if (!apolloClient || forceNew) {
    apolloClient = new ApolloClient({
      ssrMode: isServer,
      connectToDevTools: env.isDevelopment(),
      link: getLink(),
      cache: new InMemoryCache({
        dataIdFromObject: responseObject => {
          // for ClientUsersWithAccessToJobGroup Query to avoid inconsistency across multiple job groups
          if (
            responseObject.__typename === 'User' &&
            'path' in responseObject
          ) {
            return `${defaultDataIdFromObject(responseObject)}:${
              responseObject.path
            }`;
          }
          return defaultDataIdFromObject(responseObject);
        },
      }).restore(windowApolloState || {}),
    });
  }

  return apolloClient;
}
