import React, { useEffect } from 'react';
import { firebase as firebaseEntity } from '@vette/common-utils';
import { SessionStatusEnum } from '@vette/data-access';
import { useRouter } from 'next/router';

export const SessionInProgressWatcher: React.FC<{
  userId: string;
  sessionId?: string;
  session?: firebaseEntity.Session;
}> = ({ userId, sessionId, session }) => {
  const router = useRouter();

  useEffect(() => {
    if (
      userId &&
      sessionId &&
      [
        SessionStatusEnum.WAITING_FOR_APPLICANT_TO_PICKUP_CALL,
        SessionStatusEnum.WAITING_FOR_VETTER_TO_CONFIRM_APPLICANT_PICKED_UP,
        SessionStatusEnum.SESSION_IN_PROGRESS,
        SessionStatusEnum.APPLICANT_DROPPED_FROM_CALL,
        SessionStatusEnum.VETTER_DROPPED_FROM_CALL,
        SessionStatusEnum.WAITING_FOR_VETTER_TO_PROVIDE_FEEDBACK,
      ].find(s => s === session?.status)
    ) {
      if (router.asPath !== `/interview/${sessionId}`)
        router.replace(`/interview/${sessionId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, session, router.asPath]);

  return null;
};
