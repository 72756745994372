export const isDevelopment = () => {
  // https://stackoverflow.com/questions/58090082/process-env-node-env-always-development-when-building-nestjs-app-with-nrwl-nx/59805161#59805161
  return process.env['NODE' + '_ENV'] === 'development';
};

// ATTENTION: Could be used on frontend instead of 'getEnvVar' and 'tryGetEnvVar'
// EXAMPLE:
// const API_URL = env.checkEnvVarValue(
//   process.env.NEXT_PUBLIC_API_URL
// );
export const checkEnvVarValue = (value?: string) => {
  if (!value) throw new Error(`Missing environment variable`);
  return value;
};

// ATTENTION: Could not be used on frontend
export const getEnvVar = (name: string) => {
  const env = process.env[name] ?? process.env[`NEXT_PUBLIC_${name}`];
  if (!env) {
    throw new Error(`Missing environment variable: "${name}"`);
  }
  return env;
};

// ATTENTION: Could not be used on frontend
export const tryGetEnvVar = (
  name: string,
  defaultValue: string | undefined = undefined
): string | undefined => {
  try {
    return getEnvVar(name);
  } catch (e) {
    return defaultValue;
  }
};
