import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  margin: 36px 21px 0 21px;
  align-self: flex-start;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  max-width: 100vw;
`;

export const SmallImage = styled.img<{ fixedHeight?: boolean }>`
  align-self: center;
  ${({ fixedHeight }) =>
    fixedHeight &&
    css`
      height: 28px;
      width: 81px;
    `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: 350px) {
    & {
      justify-content: center;
    }
  }

  & > img:not(:last-child) {
    margin-right: 25px;
  }

  & > img:not(:first-child) {
    margin-left: 25px;
  }
`;
