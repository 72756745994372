import React from 'react';

import { ColProps, RowProps } from 'antd';
import * as S from './styles';

type Props = { $imageSrc?: string } & RowProps & ColProps;

export const PageView: React.FC<Props> = ({ children, ...props }) => {
  return (
    <S.PageView {...props}>
      <S.CenteredCol {...props}>{children}</S.CenteredCol>
    </S.PageView>
  );
};
