import React from 'react';
import { LiveChatWidget as Widget } from '@livechat/widget-react';
import { useUser } from '@vette/frontend-utils';

const LIVE_CHAT_LICENSE_KEY = process.env.NEXT_PUBLIC_LIVE_CHAT_LICENSE_KEY;

export const LiveChatWidget: React.FC = () => {
  const { user } = useUser();
  if (!LIVE_CHAT_LICENSE_KEY) return null;
  return (
    <Widget
      license={LIVE_CHAT_LICENSE_KEY}
      customerName={user ? `${user?.firstName} ${user?.lastName}` : undefined}
      customerEmail={user?.email ?? undefined}
      visibility="minimized"
    />
  );
};
