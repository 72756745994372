import React, { useCallback } from 'react';
import {
  useUpdatePresenceMutation,
  UserPresenceEnum,
} from '@vette/data-access';

import { message } from '@vette/frontend-utils';

import * as S from './styles';

export const MissedSessionModal: React.FC<{
  visible: boolean;
  onClose: (result: boolean) => void;
  userId: string;
}> = ({ visible, onClose, userId }) => {
  const [updateUserPresence] = useUpdatePresenceMutation();

  const showGeneralError = () => {
    message.error(
      'Something went wrong. Our team was notified and is intensively working on fixing the issue.'
    );
  };

  const onCloseClick = useCallback(async () => {
    onClose(false);
  }, [onClose]);

  const onGoOnlineClick = useCallback(async () => {
    const response = await updateUserPresence({
      variables: {
        userId,
        presence: UserPresenceEnum.ONLINE,
      },

      optimisticResponse: {
        __typename: 'Mutation',
        updateUserPresence: {
          __typename: 'User',
          id: userId,
          presence: UserPresenceEnum.ONLINE,
        },
      },
    });

    if (response.data?.updateUserPresence?.__typename !== 'User') {
      showGeneralError();
      return;
    }

    onClose(true);
  }, [userId, onClose, updateUserPresence]);

  return (
    <S.Modal
      visible={visible}
      centered
      closable={false}
      destroyOnClose={true}
      width={900}
      footer={[
        <S.Button
          size="large"
          actionType="primary"
          key="closeButton"
          onClick={onCloseClick}
        >
          Close
        </S.Button>,
        <S.Button
          size="large"
          actionType="secondary"
          key="goOnlineButton"
          onClick={onGoOnlineClick}
        >
          Go online
        </S.Button>,
      ]}
    >
      <S.Title>
        Oh no! We noticed you missed a Vette. <br />
        Did you step away from your computer? <br />
        We switched you to &quot;offline mode&quot;.
      </S.Title>
    </S.Modal>
  );
};
