import { Row, Col } from 'antd';
import styled from 'styled-components';

export const PageView = styled(Row)<{ $imageSrc?: string }>`
  height: 100vh;
  background: url(${({ $imageSrc }) => ($imageSrc ? `${$imageSrc}` : '')})
    no-repeat center center fixed;
  background-size: cover;
  overflow: auto;
`;

export const CenteredCol = styled(Col)`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
