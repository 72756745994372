import {
  getMessaging as firebaseGetMessaging,
  isSupported,
  onMessage,
  getToken,
  deleteToken,
} from 'firebase/messaging';
import { env } from '@vette/common-utils';
import { app } from './app';

let _messaging: ReturnType<typeof firebaseGetMessaging>;
const getMessaging = () => {
  if (!_messaging) _messaging = firebaseGetMessaging(app);
  return _messaging;
};

export const obtainPushNotificationToken = async () => {
  try {
    // Browser notification permission is not determined yet || Browser notification permission is denied
    if (!['default', 'granted'].includes(Notification.permission)) {
      return undefined;
    }

    if (typeof window === 'undefined' || !('serviceWorker' in window.navigator))
      return undefined;
    const token = await getToken(getMessaging(), {
      serviceWorkerRegistration:
        await window.navigator.serviceWorker.getRegistration(),
      ...(!env.isDevelopment() && {
        vapidKey: env.checkEnvVarValue(
          process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY
        ),
      }),
    });
    return token;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const deleteNotificationToken = async () => {
  return deleteToken(getMessaging());
};

type Subscriber = (event: { type: string; data: any }) => void;
let _subscribers: Array<Subscriber> = [];

export const subscribeToPushNotification = (subscriber: Subscriber) => {
  _subscribers.push(subscriber);
  return () => {
    _subscribers = _subscribers.filter(s => s !== subscriber);
  };
};

export const bootstrap = () => {
  isSupported().then(supported => {
    if (!supported) return;
    onMessage(getMessaging(), payload => {
      if (!payload.notification?.title) return;
      window.focus();

      _subscribers.forEach(
        s =>
          payload.data &&
          s({ data: { ...payload.data }, type: 'new-vette-notification' })
      );
      new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: payload.notification.image,
        renotify: true,
        tag: 'vetter-notification',
      });
    });
  });

  if (typeof window === 'undefined' || !('serviceWorker' in window.navigator))
    return;
  window.navigator.serviceWorker.addEventListener('message', event => {
    if (!event.data?.type) return;
    if (event.data.type === 'new-vette-notification') {
      window.focus();
      _subscribers.forEach(s => s(event.data));
    }
  });
};
