import dynamic from 'next/dynamic';
import React from 'react';

type Props = React.PropsWithChildren<{
  fallback?: React.ReactNode;
}>;

const ClientSideInner = dynamic(() => import('./_ClientSide'), { ssr: false });
export const ClientSide: React.FC<Props> = ({ children }) => {
  return <ClientSideInner>{children}</ClientSideInner>;
};
