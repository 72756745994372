import {
  initializeAuth,
  browserLocalPersistence,
  connectAuthEmulator,
} from 'firebase/auth';

import { app } from '../app';

export const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
});

if (
  typeof window !== 'undefined' &&
  process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST &&
  process.env.NEXT_PUBLIC_FIREBASE_DATABASE_EMULATOR_HOST
) {
  console.log('You are now logged to firebase auth emulator');
  // noinspection HttpUrlsUsage
  connectAuthEmulator(
    auth,
    `http://${process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST}`,
    { disableWarnings: true }
  );
}
