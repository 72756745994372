import { Grid } from 'antd';
import { message } from '@vette/frontend-utils';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { PageView } from '../../PageView';
import { theme } from '../../theme';
import { Text, Title } from '../Typography';
import * as S from './styles';

type Props = React.PropsWithChildren<{ exceptionRoutes?: string[] }>;

export const RestrictMobile: React.FC<Props> = ({
  exceptionRoutes,
  children,
}) => {
  const { lg } = Grid.useBreakpoint();
  const router = useRouter();
  const [isPrintMode, setIsPrintMode] = useState(false);

  useEffect(() => {
    window.onbeforeprint = function () {
      setIsPrintMode(true);
    };

    window.onafterprint = function () {
      setIsPrintMode(false);
    };
  }, []);

  useEffect(() => {
    message.destroy();
  }, [lg]);

  const routeAllowed = () => {
    const { route } = router;

    return exceptionRoutes?.some(r => route.startsWith(r));
  };

  if (!isPrintMode && lg !== undefined && !lg && !routeAllowed()) {
    return (
      <PageView $imageSrc="/images/boarding.svg">
        <S.ContentWrapper>
          <S.SmallImage src="/fullLogo.svg" fixedHeight />
          <Title
            $marginBottom={24}
            $marginTop={80}
            $color={theme.colors.white}
            $size={24}
          >
            We&apos;re currently not operating on mobile devices
          </Title>
          <Text
            $marginBottom={50}
            $size={18}
            $weight={400}
            $color={theme.colors.white}
          >
            Switch to a computer to log in.
          </Text>
          <S.ImageWrapper>
            <S.SmallImage src="/images/computer-check.svg" />
            <S.SmallImage src="/images/phone-check.svg" />
          </S.ImageWrapper>
        </S.ContentWrapper>
      </PageView>
    );
  }

  return <>{children}</>;
};
