import React, {
  ComponentProps,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { WarningModal } from './WarningModal';

type WarningContextProviderType = React.FC<React.PropsWithChildren> & {
  Context: typeof WarningContext;
};

type WarningPropsValueType = Omit<
  ComponentProps<typeof WarningModal>,
  'visible'
>;

const WarningContext = createContext<{
  openWarning: (props: WarningPropsValueType & { text: string }) => void;
  closeWarning: () => void;
}>({
  openWarning: () => void 0,
  closeWarning: () => void 0,
});

const WarningContextProvider: WarningContextProviderType = ({
  children,
}: React.PropsWithChildren) => {
  const [warningProps, setWarningProps] = useState<WarningPropsValueType>();

  const closeWarning = useCallback(
    () => setWarningProps(undefined),
    [setWarningProps]
  );

  const openWarning = useCallback(
    (props: WarningPropsValueType & { text: string }) => {
      setWarningProps(props);
    },
    [setWarningProps]
  );

  const handleCancel = useCallback(() => {
    closeWarning();
    warningProps?.onCancel?.();
  }, [warningProps, closeWarning]);

  return (
    <WarningContext.Provider
      value={useMemo(
        () => ({
          openWarning,
          closeWarning,
        }),
        [openWarning, closeWarning]
      )}
    >
      {children}
      {warningProps && (
        <WarningModal
          {...warningProps}
          onCancel={handleCancel}
          visible={!!warningProps}
        />
      )}
    </WarningContext.Provider>
  );
};

WarningContextProvider.Context = WarningContext;

export const WarningProvider = WarningContextProvider;
