import React, { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import * as messaging from '../firebase/messaging';

import { getApolloClient } from '../apollo';
import { Provider as FirebaseAuthProvider } from '../firebase/auth/context';
import { QueryParamProviderComponent } from './QueryParamsProviderComponent';
import { HotjarProvider } from '../hotjar';
import { UserProvider } from '../domain/UserContext';
import { GrowthBookProvider } from '../GrowthBookProvider';

type CreateCustomAppOptions = {
  ErrorFallback?: React.ComponentType<{ error: Error }>;
  wrapComponent?: (node: React.ReactNode) => React.ReactNode;
};

export const createCustomApp = ({
  ErrorFallback,
  wrapComponent,
}: CreateCustomAppOptions) =>
  Sentry.withErrorBoundary(
    ({ Component, pageProps }: AppProps) => {
      const client = pageProps.apolloClient ?? getApolloClient();
      useEffect(() => {
        messaging.bootstrap();
      }, []);
      return (
        <FirebaseAuthProvider>
          <ApolloProvider client={client}>
            <UserProvider>
              <QueryParamProviderComponent>
                <GrowthBookProvider>
                  <HotjarProvider>
                    {wrapComponent ? (
                      wrapComponent(<Component {...pageProps} />)
                    ) : (
                      <Component {...pageProps} />
                    )}
                  </HotjarProvider>
                </GrowthBookProvider>
              </QueryParamProviderComponent>
            </UserProvider>
          </ApolloProvider>
        </FirebaseAuthProvider>
      );
    },
    {
      fallback: ({ error }) =>
        ErrorFallback ? (
          <ErrorFallback error={error} />
        ) : (
          <>Something went wrong</>
        ),
    }
  );
