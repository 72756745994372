import { createGlobalStyle } from 'styled-components';
import { theme } from '../theme';

export const GlobalStyles = createGlobalStyle`
  * {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  }

  a.MuiTypography-root {
    text-decoration: none;
  }

  .ant-message-notice {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .ant-message-notice-content {
    display: flex;
    background: none;
    margin: 8px auto 0;
    box-shadow: ${theme.styles.shadows.card};
    border-radius: 16px;

    color: white;
    min-width: 50%;
    max-width: 372px;
    text-align: left;
    overflow: hidden;
    padding: 0;
  }

  .ant-message-success,
  .ant-message-error,
  .ant-message-info,
  .ant-message-loading {
    padding: 18px 24px;
    display: flex;
    flex: 1;

    & > span {
      font-weight: 600;
      font-size: 15px;
      line-height: 140%;
    }
  }

  .ant-message-success,
  .ant-message-info,
  .ant-message-error {
    & > span {
      & > svg {
        display: none;
      }
    }
  }

  .ant-message-error {
    background: ${theme.colors.red40};
  }

  .ant-message-success {
    background-color: ${theme.colors.green50};
  }

  .ant-message-info,
  .ant-message-loading {
    background-color: ${theme.colors.white};
    color:  ${theme.colors.black};
  }

  .ant-avatar.ant-avatar-image img {
    object-fit: cover;
  }

  .ant-table-filter-column {
    justify-content: none;
    width: fit-content;
  }

  .ant-tooltip-compact {
    max-width: none;
    font-weight: 600;

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      padding: 8px 16px;
      background-color: ${theme.colors.grey90};
    }
  }
`;
