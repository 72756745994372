import React, { useEffect } from 'react';
import { usePreviousDistinct } from 'react-use';
import { useUserQuery, UserDataFragment } from '@vette/data-access';
import { userRules } from '@vette/common-utils';
import { Claims, FirebaseClaims } from '../../firebase';
import { message } from '../../message';
import { useCustomRouter } from '../../router';
import { notification } from 'antd';

const UserContext = React.createContext<{
  rules: userRules.UserRuleOutput;
  user?: UserDataFragment;
  claims?: Claims;
  loading: boolean;
}>({ rules: userRules.EMPTY_USER_RULES_OUTPUT, loading: true });

const InnerUserProvider: React.FC<
  React.PropsWithChildren<{ claims?: Claims | null }>
> = ({ claims, children }) => {
  const { router } = useCustomRouter();

  const { data, loading } = useUserQuery({
    variables: { userId: claims?.id || '' },
    skip: !claims?.id,
    onError: () => {
      message.error(
        'Failed to obtain information about your dashboard. Please, make sure your network is reliably working.'
      );
    },
  });

  const user = data?.user;
  const rulesInput = userRules.mapUserDataToUserRuleInput({
    ...claims,
    ...user,
  });

  const rules = user ? userRules.applyRules(rulesInput) : null;

  const previousHangingMessageText = usePreviousDistinct(
    rules?.hangingMessageText
  );

  useEffect(() => {
    if (
      previousHangingMessageText &&
      rules?.hangingMessageText !== previousHangingMessageText
    ) {
      notification.close(previousHangingMessageText);
    }
  }, [rules?.hangingMessageText, previousHangingMessageText]);

  useEffect(() => {
    if (!rules?.hangingMessageText || loading) {
      return;
    }

    notification.destroy();

    const functionName = (rules as { hangingMessageType: 'info' | 'warning' })
      .hangingMessageType;

    notification[functionName]({
      message: rules.hangingMessageText,
      duration: 0,
      placement: 'bottomRight',
      maxCount: 1,
      key: rules.hangingMessageText,
    });
  }, [loading, rules?.hangingMessageText, router.asPath]);

  const isAtSettings = router.asPath === '/settings';
  useEffect(() => {
    if (!loading && !isAtSettings && !rules?.beAbleToLeaveSettingsPage)
      router.replace('/settings');
  }, [loading, rules?.beAbleToLeaveSettingsPage, isAtSettings]);

  const isAtTerms = router.asPath === '/terms';
  useEffect(() => {
    if (
      // this is needed because during invitation process user doesn't have claims for a short while
      !router.asPath.startsWith('/invitation') &&
      !loading &&
      !isAtTerms &&
      !rules?.beAbleToLeaveTermsPage
    ) {
      router.replace('/terms');
    }
  }, [loading, rules?.beAbleToLeaveTermsPage, isAtTerms]);

  return (
    <UserContext.Provider
      value={{
        rules: rules || userRules.EMPTY_USER_RULES_OUTPUT,
        user: user || undefined,
        claims: claims || undefined,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider: React.FC<React.PropsWithChildren> = props => {
  return (
    <FirebaseClaims fallback={props.children}>
      {claims => <InnerUserProvider {...props} claims={claims} />}
    </FirebaseClaims>
  );
};

export const useUser = () =>
  React.useContext(UserContext) || {
    rules: userRules.EMPTY_USER_RULES_OUTPUT,
    loading: true,
  };
