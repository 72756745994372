import { onError } from '@apollo/client/link/error';
import { auth } from '../firebase';

// https://www.apollographql.com/docs/react/data/error-handling#retrying-operations
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  for (const err of graphQLErrors ?? []) {
    if (err.extensions?.code === 'REVOKED_AUTH_TOKEN') {
      auth.signOut();
    }
  }

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
  return;
});
