import React from 'react';
import * as S from './styles';

type Element = { title: string; content: string };

type Props = {
  elements: Element[];
};

export const Frame: React.FC<Props> = ({ elements }) => {
  return (
    <S.Wrapper>
      {elements.map(element => (
        <S.Section key={`${element.title}${element.content}`}>
          <S.Heading className="text-wrapper">{element.title}</S.Heading>
          <S.Content className="span">{element.content}</S.Content>
        </S.Section>
      ))}
    </S.Wrapper>
  );
};
