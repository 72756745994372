import React, { useEffect } from 'react';
import hotjarLib from '@hotjar/browser';
import { isEqual } from 'lodash';
import { UserDataFragment } from '@vette/data-access';
import { useUser } from '../domain';

const hotjarDebug = process.env.HOTJAR_DEBUG;

let currentUser: UserDataFragment | null = null;

const isDebug =
  hotjarDebug ||
  (typeof window !== 'undefined' &&
    ['localhost', 'staging'].some(environment =>
      window.location.hostname.includes(environment)
    ));

const useHotjarSetup = () => {
  if (isDebug) {
    hotjarLib.identify = (id, data) => {
      console.log('Hotjar identify', id, data);
      return true;
    };
  }

  const { user } = useUser();

  useEffect(() => {
    // deduplicate identify calls:
    if (isEqual(user, currentUser) || (user == null && currentUser == null))
      return;
    if (user) {
      hotjarLib.identify(user.id, {
        userId: user.id,
        userName: `${user.firstName} ${user.lastName}`,
        userRole: user.role,
        ...(user.client?.companyName && {
          clientName: user.client.companyName,
        }),
      });
      currentUser = user;
    } else {
      hotjarLib.identify(null, {});
      currentUser = null;
    }
  }, [user]);

  return hotjarLib;
};

const HotjarContext = React.createContext<typeof hotjarLib | undefined>(
  undefined
);

export const HotjarProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const hotjar = useHotjarSetup();

  return (
    <HotjarContext.Provider value={hotjar}>{children}</HotjarContext.Provider>
  );
};

export const useHotjar = () => {
  const hotjar = React.useContext(HotjarContext);

  if (!hotjar && hotjarLib.isReady()) {
    throw new Error(
      'Hotjar context not found. Did you forget to wrap your code in a <HotjarProvider>?'
    );
  }

  return hotjar;
};

export const event = (actionName: string) => {
  if (isDebug) {
    console.log('Hotjar event', actionName);
    return;
  }

  hotjarLib.event(actionName);
};
