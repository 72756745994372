import React, { useEffect, useState } from 'react';
import { firebase as firebaseEntity } from '@vette/common-utils';
import { AcceptSessionModal } from '@vette/ui-components';
import {
  VetterDebugLogEventsEnum,
  SessionStatusEnum,
} from '@vette/data-access';
import { useVetterLog } from '../hooks/useVetterLog';

export const NewSessionWatcher: React.FC<{
  userId: string;
  sessionId?: string;
  session?: firebaseEntity.Session;
}> = ({ userId, sessionId, session }) => {
  const [showModal, setShowModal] = useState(false);
  const log = useVetterLog();

  useEffect(() => {
    if (
      session &&
      session.status ===
        SessionStatusEnum.WAITING_FOR_VETTER_TO_ACCEPT_SESSION_REQUEST
    ) {
      setShowModal(true);

      log({
        userId,
        globalActivityHistoryEvent:
          VetterDebugLogEventsEnum.VETTER_SAW_SESSION_REQUEST_MODAL,
        metadata: {
          sessionId,
          session,
        },
      });
    } else {
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, session]);

  return (
    <>
      {userId && sessionId && session && (
        <AcceptSessionModal
          sessionId={sessionId}
          visible={showModal}
          timeout={session.statusTimeout}
        />
      )}
    </>
  );
};
