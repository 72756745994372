import React, { useCallback, useEffect } from 'react';
import { GrowthBookProvider as GrowthBookProviderOriginal } from '@growthbook/growthbook-react';
import { GrowthBook } from './GrowthBook';
import { useUser } from '../domain/UserContext';
import { mapValues } from 'lodash';
import { useApolloClient } from '@apollo/client';
import {
  AssignUserGrowthbookExperimentVariationDocument,
  AssignUserGrowthbookExperimentVariationMutation,
  AssignUserGrowthbookExperimentVariationMutationVariables,
} from '@vette/data-access';
import { useRouter } from 'next/router';

const GROWTHBOOK_API_HOST = process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST;
const GROWTHBOOK_API_KEY = process.env.NEXT_PUBLIC_GROWTHBOOK_API_KEY;

function getRidOfNullInTheUnion<T>(x: T | undefined | null) {
  return x || undefined;
}

const gb = new GrowthBook({
  features: {},
  subscribeToChanges: true,
});

const updateGrowthBookURL = () => {
  gb.setURL(window.location.href);
};

const InnerGrowthBookProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [attributes, setAttributes] =
    React.useState<Record<string, string | boolean | undefined>>();

  const router = useRouter();

  const apolloClient = useApolloClient();

  const { user } = useUser();

  const appendAttributes = useCallback(
    (
      appendedAttributes: Record<string, string | boolean | undefined | null>
    ) => {
      setAttributes(attributes => ({
        ...attributes,
        ...mapValues(appendedAttributes, getRidOfNullInTheUnion),
      }));
    },
    [attributes]
  );

  useEffect(() => {
    return gb.subscribeToExperimentVariations((experiment, result) => {
      const userId = gb.getAttributes()?.id;
      if (userId) {
        apolloClient.mutate<
          AssignUserGrowthbookExperimentVariationMutation,
          AssignUserGrowthbookExperimentVariationMutationVariables
        >({
          mutation: AssignUserGrowthbookExperimentVariationDocument,
          variables: {
            userId,
            experimentId: experiment.key,
            variationId: result.key,
          },
        });
      }
    });
  }, [apolloClient]);

  useEffect(() => {
    router.events.on('routeChangeComplete', updateGrowthBookURL);
    return () => {
      router.events.off('routeChangeComplete', updateGrowthBookURL);
    };
  }, [router]);

  useEffect(() => {
    if (attributes) {
      gb.setAttributes(attributes);
    }
  }, [attributes]);

  useEffect(() => {
    if (user) {
      appendAttributes({
        id: user.id,
        email: user.email,
        company: user.client?.companyName,
        role: user.role,
        loggedIn: true,
      });
    }
  }, [user]);

  return (
    <GrowthBookProviderOriginal growthbook={gb}>
      {children}
    </GrowthBookProviderOriginal>
  );
};

export const GrowthBookProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  if (!GROWTHBOOK_API_HOST || !GROWTHBOOK_API_KEY) {
    return children;
  }

  return <InnerGrowthBookProvider>{children}</InnerGrowthBookProvider>;
};

export const growthbook = gb;
