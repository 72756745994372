import {
  AppContext,
  APP_CONTEXT_OPTIONS,
  firebase,
  next,
} from '@vette/frontend-utils';
import {
  ErrorFallback,
  GlobalStyles,
  RestrictMobile,
  theme,
  WarningProvider,
  LiveChatWidget,
} from '@vette/ui-components';
import 'antd/dist/antd.less';
import Head from 'next/head';
import { PushNotificationMessaging, SessionWatchers } from '@vette/pages';
import { ThemeProvider } from 'styled-components';
import { UserRoleEnum } from '@vette/data-access';
import { ClientUserPermission } from '@prisma/client';
import * as redirects from '../config/redirects';

const measurementId = process.env.NEXT_PUBLIC_ENTERPRISE_MEASUREMENT_ID;

export default next.app.createCustomApp({
  ErrorFallback,
  wrapComponent: node => {
    return (
      <AppContext.Provider value={APP_CONTEXT_OPTIONS.client}>
        <ThemeProvider
          theme={{ ...theme, config: { ...theme.config, allowDarkMode: true } }}
        >
          <WarningProvider>
            {measurementId && (
              <next.RouteTracker measurementId={measurementId} />
            )}
            <GlobalStyles />
            <firebase.RedirectGuard
              loggedInRedirects={redirects.loggedInRedirects}
              loggedOutRedirects={redirects.loggedOutRedirects}
            >
              <Head>
                <title>Vette | Enterprise Application</title>
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <meta pingdom="4DE2F5EC3DD5F05474C5EA45D359BB6399BE0ABE1897FCAE83D8D4B48DCD02B1" />
                }
              </Head>
              <firebase.FirebaseClaims required={{ id: true }}>
                {({ id }) => id && <PushNotificationMessaging userId={id} />}
              </firebase.FirebaseClaims>
              <next.ClientSide>
                <RestrictMobile
                  exceptionRoutes={['/login', '/signup', '/invitation']}
                >
                  {node}
                </RestrictMobile>
                <next.ClientSide>
                  <firebase.FirebaseClaims required={{ id: true, role: true }}>
                    {({ id, clientUserPermission, role }) => (
                      <>
                        {clientUserPermission ===
                          ClientUserPermission.INTERVIEW ||
                        role === UserRoleEnum.ADMIN ? (
                          <LiveChatWidget />
                        ) : null}
                        <SessionWatchers userId={id} />
                      </>
                    )}
                  </firebase.FirebaseClaims>
                </next.ClientSide>
              </next.ClientSide>
            </firebase.RedirectGuard>
          </WarningProvider>
        </ThemeProvider>
      </AppContext.Provider>
    );
  },
});
