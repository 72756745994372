import styled from 'styled-components';
import { Skeleton } from 'antd';

export const PagePlaceholder = styled.div
  // https://github.com/styled-components/styled-components/issues/3125#issuecomment-811334966
  .withConfig({
    componentId: 'PagePlaceholder',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any)`
  display: flex;
  flex: 0 auto;
  flex-direction: column;
  padding: 48px 72px;
  border-radius: 18px;
  background: ${({ theme }) => theme.colors.white};
  margin: 36px;
  gap: 72px;
`;

export const BoxPlaceholder = styled.div`
  display: flex;
  flex: 0 auto;
  flex-direction: column;
  padding: 12px 14px;
  margin: 4px;
  gap: 8px;
`;

const SKELETON_HEIGHT = {
  mini: '8px',
  small: '32px',
  medium: '40px',
  large: '48px',
};

export const SkeletonBox = styled(Skeleton).attrs(
  ({ paragraph = false, title = true }) => ({
    title,
    paragraph,
    active: true,
  })
)<{ width?: string; size: keyof typeof SKELETON_HEIGHT; rounded?: boolean }>`
  width: ${({ width = '100%' }) => width};

  .ant-skeleton-title {
    border-radius: ${({ rounded }) => (rounded ? '12px' : '8px')};
    height: ${({ size }) => SKELETON_HEIGHT[size]};
  }
`;
