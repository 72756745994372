import React from 'react';
import { Col, Row } from 'antd';
import * as S from './styles';

export const PageSkeleton: React.FC = () => {
  return (
    <S.PagePlaceholder>
      <Row justify="space-between" align="middle">
        <S.SkeletonBox width="420px" size="medium" />
        <S.SkeletonBox width="130px" size="large" rounded />
      </Row>
      <S.SkeletonBox size="small" paragraph title />
    </S.PagePlaceholder>
  );
};

export const FrameSkeleton: React.FC = () => {
  return (
    <Row
      style={{ width: '100%', marginLeft: 4 }}
      gutter={24}
      justify="center"
      align="middle"
    >
      <Col flex="1">
        <S.SkeletonBox size="small" />
        <S.SkeletonBox size="small" />
      </Col>
      <Col flex="1">
        <S.SkeletonBox size="small" />
        <S.SkeletonBox size="small" />
      </Col>
    </Row>
  );
};
