import { UserRoleEnum, ClientUserPermissionEnum } from '@vette/data-access';
import { useFirebase } from '../firebase';

export const useRole = () => {
  const { claims } = useFirebase();
  const isLoggedIn = !!claims;
  const isVetter = claims?.role === UserRoleEnum.VETTER;
  const isAdmin = claims?.role === UserRoleEnum.ADMIN;
  const isClient = claims?.role === UserRoleEnum.CLIENT;

  const isClientInterviewer =
    isClient &&
    claims?.clientUserPermission === ClientUserPermissionEnum.INTERVIEW;

  const isClientManager =
    isClient &&
    claims?.clientUserPermission === ClientUserPermissionEnum.MANAGE;

  return {
    role: claims?.role,
    clientId: claims?.clientId,
    clientUserPermission: claims?.clientUserPermission,
    isAdmin,
    isVetter,
    isClient,
    isClientInterviewer,
    isClientManager,
    isLoggedIn,
  };
};
