import { useCallback, useEffect, useState } from 'react';
import { pick, isEmpty } from 'lodash';
import * as Sentry from '@sentry/nextjs';
import { onAuthStateChanged, User, signOut } from 'firebase/auth';

import { auth } from './initializeAuth';
import { useRouter } from 'next/router';
import { getApolloClient, resetApolloLink } from '../../apollo';
import {
  ClientUserPermissionEnum,
  LogoutUserDocument,
  UserRoleEnum,
} from '@vette/data-access';
import { userAccess } from '@vette/common-utils';

// TODO: move it under @vette/common-utils
// Cause the same class is defined at "apps/api/src/services/domain/CurrentUser/Claims.ts"
export type Claims = {
  id: string;
  role: UserRoleEnum;
  clientId?: string;
  clientUserPermission?: ClientUserPermissionEnum;
  canManageBilling?: boolean;
  access?: userAccess.AccessPermission[];
};

export const claimKeys = [
  'id',
  'role',
  'clientId',
  'clientUserPermission',
  'canManageBilling',
  'access',
] satisfies (keyof Claims)[];

export const useAuth = () => {
  const [user, setUser] = useState<User>();
  const [claims, setClaims] = useState<Claims>();

  const { query } = useRouter();

  const isAdmin = claims?.role === 'ADMIN';

  const normalizedClaims = isAdmin
    ? {
        ...claims,
        clientId: (query?.clientId ?? claims?.clientId ?? '') as string,
      }
    : claims;

  const refreshUser = useCallback(async () => {
    if (user) {
      await user.reload();
    }
  }, [user]);

  const refetchToken = useCallback(async () => {
    const tokenResult = await user?.getIdTokenResult(true);
    setClaims(pick(tokenResult?.claims as unknown as Claims, claimKeys));
    return tokenResult;
  }, [user]);

  useEffect(() => {
    (async () => {
      if ((!claims || isEmpty(claims)) && user) {
        const tokenResult = await user.getIdTokenResult();
        setClaims(pick(tokenResult?.claims as unknown as Claims, claimKeys));
        // this is because of subscriptions
        resetApolloLink();
      }
    })();

    if (!user && claims) {
      setClaims(undefined);
    }
  }, [user, claims]);

  const logout = useCallback(async () => {
    try {
      console.log('Logging out', new Error().stack);
      const apolloClient = getApolloClient();
      await apolloClient.mutate({
        mutation: LogoutUserDocument,
        variables: {},
        errorPolicy: 'ignore',
      });
      await apolloClient.clearStore();
    } catch (e) {
      console.log(e);
    }

    setClaims(undefined);
    return signOut(auth);
  }, []);

  useEffect(() => {
    return onAuthStateChanged(auth, userOrNull => {
      if (userOrNull) {
        Sentry.setUser({
          id: userOrNull.uid,
        });
      }
      setUser(userOrNull ?? undefined);
    });
  }, []);

  return { user, refetchToken, refreshUser, logout, claims: normalizedClaims };
};
