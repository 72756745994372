import { ButtonProps, Tooltip } from 'antd';
import React, { ReactNode } from 'react';
import * as S from './styles';

type Props = {
  actionType?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'last';
  size?: S.CustomButtonProps['$size'];
  fullWidth?: boolean;
  tooltipTitle?: ReactNode;
} & Omit<ButtonProps, 'size'> &
  Omit<S.CustomButtonProps, '$size'>;

export const Button: React.FC<Props> = ({
  actionType,
  size,
  tooltipTitle,
  fullWidth,
  ...props
}) => {
  // Figma mapping to Antd button types:
  // https://www.figma.com/file/jZpQbcPVfKqKVWfPtdRtcj/Design-system-%E2%80%93-Vette?node-id=119%3A4040
  let type = props.type;
  switch (actionType) {
    case 'primary':
      type = 'primary';
      break;
    case 'secondary':
      type = 'default';
      break;
    case 'tertiary':
      type = 'ghost';
      break;
    case 'quaternary':
      type = 'text';
      break;
    case 'last':
      type = 'link';
      break;
  }

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle}>
        <S.Button {...props} $size={size} type={type} $fullWidth={fullWidth} />
      </Tooltip>
    );
  }

  return (
    <S.Button {...props} $size={size} type={type} $fullWidth={fullWidth} />
  );
};
