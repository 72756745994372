import * as Sentry from '@sentry/nextjs';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { usePreviousDistinct } from 'react-use';
import { firebase as firebaseEntity } from '@vette/common-utils';
import { message, useAppContext } from '@vette/frontend-utils';
import { SessionStatusEnum } from '@vette/data-access';
import { useApolloClient } from '@apollo/client';

const EXPECTED_PREVIOUS_STATUSES = [
  SessionStatusEnum.SESSION_FINISHED_SUCCESSFULLY,
  SessionStatusEnum.SESSION_FINISHED_WITH_AN_ISSUE,
  SessionStatusEnum.APPLICANT_DID_NOT_PICKUP_CALL,
  SessionStatusEnum.VETTER_CLAIMS_APPLICANT_DID_NOT_PICKUP_CALL,
  SessionStatusEnum.APPLICANT_WAS_NOT_REACHABLE,
  SessionStatusEnum.WAITING_FOR_APPLICANT_TO_PICKUP_CALL,
];

// for debugging purposes:
let lastRecordedData: any = {};

export const SessionEndWatcher: React.FC<{
  userId: string;
  sessionId?: string;
  session?: firebaseEntity.Session;
}> = ({ session, sessionId: firebaseSessionId, userId }) => {
  const { app } = useAppContext();
  const router = useRouter();
  const previousStatus = usePreviousDistinct(session?.status);
  const currentStatus = session?.status;

  const apolloClient = useApolloClient();

  lastRecordedData = {
    session,
    sessionId: firebaseSessionId,
    userId,
    previousStatus,
  };

  useEffect(() => {
    const { sessionId: urlSessionId } = router.query ?? {};

    if (
      urlSessionId &&
      router.asPath === `/interview/${urlSessionId}` &&
      previousStatus &&
      !currentStatus
    ) {
      localStorage.removeItem(`interview-${urlSessionId}`);

      if (previousStatus === SessionStatusEnum.LOOKING_FOR_VETTER) {
        // skip
      } else if (EXPECTED_PREVIOUS_STATUSES.some(s => s === previousStatus)) {
        if (
          [
            SessionStatusEnum.WAITING_FOR_APPLICANT_TO_PICKUP_CALL,
            SessionStatusEnum.APPLICANT_WAS_NOT_REACHABLE,
            SessionStatusEnum.APPLICANT_DID_NOT_PICKUP_CALL,
          ].includes(previousStatus as SessionStatusEnum)
        ) {
          message.error(
            `Unfortunately, we were unable to connect you with the applicant. 
            We are working on getting another Vette to you!`
          );
        }
        router.replace('/dashboard#vette_completed');
      } else {
        router.replace(`/dashboard#vette_failed=${previousStatus}`).then(() => {
          previousStatus === SessionStatusEnum.WAITING_FOR_VETTER_TO_PRESS_DIGIT
            ? message.error(
                'We did not receive an input from your phone device. Please make sure to press the digit 1 on your phone device after picking up a call.'
              )
            : message.error(
                `Unfortunately, we were unable to connect you with the applicant. 
            We are working on getting another Vette to you!`
              );
        });

        if (
          previousStatus !== SessionStatusEnum.WAITING_FOR_VETTER_TO_PRESS_DIGIT
        )
          (async () => {
            const eventId = Sentry.captureException(
              new Error(
                `Session id=${urlSessionId} ended unexpectedly for user firebaseUid=${userId} with previous status=${previousStatus}.`
              ),
              { extra: lastRecordedData }
            );

            if (app !== 'Client') {
              Sentry.showReportDialog({
                eventId,
                labelSubmit: 'Submit Feedback',
              });
            }

            await Sentry.flush();
          })();
      }

      setTimeout(() => {
        apolloClient.cache.evict({ fieldName: 'user' });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userId,
    session,
    previousStatus,
    firebaseSessionId,
    router,
    apolloClient,
  ]);

  return null;
};
