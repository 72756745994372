import React, { useState } from 'react';
import { useInterval } from 'react-use';
import { date } from '@vette/common-utils';

type TimeFormats = 'plain-seconds' | '00:00';

type Props = {
  targetTimestamp?: number;
  onFinish?: () => void;
  format?: TimeFormats;
};

export const TimerCountdown: React.FC<Props> = ({
  targetTimestamp,
  onFinish,
  format = 'plain-seconds',
}) => {
  const calculateSeconds = () => {
    if (!targetTimestamp) return 0;
    const utc = date.utcTimestamp();
    return Math.floor((targetTimestamp - utc) / 1000);
  };

  const currentSecondsTillFinish = calculateSeconds();
  const [seconds, setSeconds] = useState(currentSecondsTillFinish);

  useInterval(
    () => {
      if (seconds < 0) return;
      const secondsTillFinish = calculateSeconds();
      if (secondsTillFinish <= 0) {
        onFinish?.();
      }
      setSeconds(secondsTillFinish);
    },
    // Stop the timer once it runs out:
    currentSecondsTillFinish >= 0 ? 1000 : null
  );

  const secondsOrZero = Math.max(seconds, 0);

  if (format === '00:00')
    return date.formatSecondsToMinutesAndSeconds(secondsOrZero);

  return <>{Math.max(seconds, 0)}</>;
};
