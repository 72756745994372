import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  VetterLogDocument,
  VetterLogMutation,
  VetterLogMutationVariables,
} from '@vette/data-access';

export const useVetterLog = () => {
  const client = useApolloClient();

  return useCallback(
    (variables: VetterLogMutationVariables) => {
      client.mutate<VetterLogMutation, VetterLogMutationVariables>({
        variables,
        mutation: VetterLogDocument,
      });
    },
    [client]
  );
};
