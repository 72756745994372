import { isDevelopment } from './env';

const KNOWN_ERROR_MESSAGE_SUBSTRINGS = [
  'ResizeObserver loop limit exceeded',
  'auth/user-disabled',
  'auth/network-request-failed',
  'auth/invalid-phone-number',
].map(msg => msg.toLowerCase());

export const isKnownError = (errorMessage?: string) => {
  if (!errorMessage) return false;

  if (isDevelopment()) return true;

  if (KNOWN_ERROR_MESSAGE_SUBSTRINGS.includes(errorMessage.toLowerCase()))
    return true;

  return false;
};

export const sentryBeforeSend = <
  TEvent,
  TEventHint extends { originalException?: unknown }
>(
  event: TEvent,
  hint?: TEventHint
) => {
  const error = hint?.originalException;
  let errorMessage: string | undefined;
  if (typeof error === 'string') {
    errorMessage = error;
  } else if (error instanceof Error) {
    errorMessage = error.message;
    if ('code' in error) {
      errorMessage = `${
        (error as Error & { code: string }).code
      } ${errorMessage}`;
    }
  }

  if (isKnownError(errorMessage)) {
    return null;
  }

  return event;
};

export const NON_FIELD_ERROR_FIELD_NAME = 'non_field_error';
export const REQUIRE_ATLEAST_ONE_CORRECT_ANSWER =
  'Please select at least 1 correct answer';
export const REQUIRE_ATLEAST_ONE_CORRECT_ANSWER_ERROR =
  'Make sure you have selected at least 1 correct answers in a deal-breaker question.';
export const UNIQUE_SCRIPT_NAME_ERROR =
  'This name is already used. Please choose a unique interview script name.';
