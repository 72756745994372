import React from 'react';

import * as S from './styles';

type Props = React.PropsWithChildren;

export const GlobalStyles: React.FC<Props> = ({ children }) => {
  return (
    <>
      <S.GlobalStyles />
      {children}
    </>
  );
};
