import React, { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { useAddPushNotificationTokenMutation } from '@vette/data-access';
import { date } from '@vette/common-utils';
import { firebase, hooks, message, useUser } from '@vette/frontend-utils';
import { useWarning } from '@vette/ui-components';

const ASK_TO_ENABLE_NOTIFICATIONS_EVERY = 1000 * 60 * 60 * 24 * 5; // every 5 days

type Props = { userId: string };

export const PushNotificationMessaging: React.FC<Props> = ({ userId }) => {
  const [addPushNotificationToken] = useAddPushNotificationTokenMutation();

  const warning = useWarning();
  const [maybeLater, setMaybeLater] = useLocalStorage<number>(
    'notifications-denied-ask-again-till'
  );

  const { user } = useUser();
  const { isClientInterviewer, isVetter } = hooks.useRole();

  const showNotificationModal =
    isClientInterviewer || (isVetter && !!user?.termsAcceptedAt);

  useEffect(() => {
    (async () => {
      if (
        showNotificationModal &&
        (!maybeLater || maybeLater < date.utcTimestamp()) &&
        Notification.permission === 'default'
      ) {
        warning.openWarning({
          text: `Don't miss another Vette! Enable notifications to receive Vette requests even when this website is in the background.`,
          okText: 'Enable Notifications',
          cancelText: 'Maybe Later',
          cancelProps: {
            'aria-label': 'Maybe Later',
          },
          onOk: async () => {
            let token: string | undefined;
            try {
              token = await firebase.messaging.obtainPushNotificationToken();
              if (!token) {
                message.error(
                  'It looks like notification permission for this site has been disabled. Please check the browser permissions and allow notifications for this site.”'
                );
              }
            } catch (e) {
              message.error(
                'Failed to enable push notifications. Please adjust your browser settings to enable notifications again.'
              );
              setMaybeLater(
                date.utcFutureTimestamp(ASK_TO_ENABLE_NOTIFICATIONS_EVERY)
              );
              return warning.closeWarning();
            }

            if (token) {
              await addPushNotificationToken({
                variables: {
                  userId,
                  token,
                },
              });
              warning.closeWarning();
              message.success(
                `Your notifications have been successfully enabled! 🎉`
              );
            }
          },
          cancelAfterOk: false,
          onCancel: () => {
            setMaybeLater(
              date.utcFutureTimestamp(ASK_TO_ENABLE_NOTIFICATIONS_EVERY)
            );
          },
        });
      }
    })();
  }, [
    showNotificationModal,
    maybeLater,
    userId,
    addPushNotificationToken,
    setMaybeLater,
    warning,
  ]);

  return null;
};
