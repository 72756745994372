/**
 * This file implements the following formalization:
 * https://oakslab.atlassian.net/wiki/spaces/VET/pages/2645524491/User+Requirements+and+Actions+solution
 */
import type { ClientUserPermission, UserRole } from '@prisma/client';
import { userAttribute } from '.';

// https://oakslab.atlassian.net/wiki/spaces/VET/pages/2645524491/User+Requirements+and+Actions+solution#User-Roles
export enum FormalizedUserRole {
  VETTER = 'VETTER',
  INTERVIEWER = 'INTERVIEWER',
  MANAGER = 'MANAGER',
  ADMIN = 'ADMIN',
}

// https://oakslab.atlassian.net/wiki/spaces/VET/pages/2645524491/User+Requirements+and+Actions+solution#User-Requirements
export type UserRuleInput = {
  role?: FormalizedUserRole;
  acceptedTerms?: boolean;
  hasProfileInfo?: boolean;
  hasProfilePicture?: boolean;
  hasAttributes?: boolean;
  isApprovedByAdmin?: boolean;
};

type GoOnlineRuleOutput =
  | {
      beAbleToGoOnline: true;
    }
  | {
      beAbleToGoOnline: false;
      unableToGoOnlineReason:
        | 'MISSING_PROFILE_INFO'
        | 'NOT_APPROVED_BY_ADMIN'
        | 'UNKNOWN';
    };

type HangingMessageRuleOutput =
  | {
      hangingMessageText?: undefined;
    }
  | {
      hangingMessageText: string;
      hangingMessageType: 'info' | 'warning';
    };

// https://oakslab.atlassian.net/wiki/spaces/VET/pages/2645524491/User+Requirements+and+Actions+solution#User-Behaviors
export type UserRuleOutput = {
  beAbleToLeaveTermsPage: boolean;
  beAbleToLeaveSettingsPage: boolean;
  canSetupStripe: boolean;
} & GoOnlineRuleOutput &
  HangingMessageRuleOutput;

// https://oakslab.atlassian.net/wiki/spaces/VET/pages/2645524491/User+Requirements+and+Actions+solution#Rules
export type UserRule = (input: UserRuleInput) => UserRuleOutput | undefined;

export const DEFAULT_USER_RULES_OUTPUT: UserRuleOutput = {
  beAbleToLeaveTermsPage: true,
  beAbleToLeaveSettingsPage: true,
  beAbleToGoOnline: true,
  canSetupStripe: false,
};

export const EMPTY_USER_RULES_OUTPUT: UserRuleOutput = {
  beAbleToLeaveTermsPage: false,
  beAbleToLeaveSettingsPage: false,
  beAbleToGoOnline: false,
  canSetupStripe: false,
  unableToGoOnlineReason: 'UNKNOWN',
};

// https://oakslab.atlassian.net/wiki/spaces/VET/pages/2645524491/User+Requirements+and+Actions+solution#Rules
export const applyRules: UserRule = ({
  role,
  acceptedTerms,
  hasProfileInfo,
  hasAttributes,
  hasProfilePicture,
  isApprovedByAdmin,
}) => {
  const isWholeProfileSetUp =
    hasProfileInfo && hasAttributes && hasProfilePicture;

  switch (role) {
    // https://oakslab.atlassian.net/wiki/spaces/VET/pages/2645524491/User+Requirements+and+Actions+solution#As-a-Vetter-or-Admin-Acting-as-a-Vetter
    case FormalizedUserRole.VETTER:
      if (!acceptedTerms)
        return { ...DEFAULT_USER_RULES_OUTPUT, beAbleToLeaveTermsPage: false };

      if (!isWholeProfileSetUp)
        return {
          ...DEFAULT_USER_RULES_OUTPUT,
          beAbleToLeaveSettingsPage: false,
          beAbleToGoOnline: false,
          unableToGoOnlineReason: 'MISSING_PROFILE_INFO',
          hangingMessageText:
            'Before going online, you must first complete all required information in your Settings.',
          hangingMessageType: 'warning',
        };

      if (!isApprovedByAdmin)
        return {
          ...DEFAULT_USER_RULES_OUTPUT,
          beAbleToGoOnline: false,
          unableToGoOnlineReason: 'NOT_APPROVED_BY_ADMIN',

          hangingMessageText:
            'You are on the waitlist to become a Vetter. We will inform you once your account is approved.',
          hangingMessageType: 'info',
        };

      return { ...DEFAULT_USER_RULES_OUTPUT, canSetupStripe: true };
    // https://oakslab.atlassian.net/wiki/spaces/VET/pages/2645524491/User+Requirements+and+Actions+solution#As-a-Client-Interviewer-or-Admin-acting-as-a-Client-Interviewer
    case FormalizedUserRole.INTERVIEWER:
      if (!isWholeProfileSetUp)
        return {
          ...DEFAULT_USER_RULES_OUTPUT,
          beAbleToLeaveSettingsPage: true,
          beAbleToGoOnline: false,
          unableToGoOnlineReason: 'MISSING_PROFILE_INFO',
          hangingMessageText:
            'Before going online, you must first complete all required information in your Settings.',
          hangingMessageType: 'warning',
        };
      break;
    // https://oakslab.atlassian.net/wiki/spaces/VET/pages/2645524491/User+Requirements+and+Actions+solution#As-a-Client-Manager
    case FormalizedUserRole.MANAGER:
      if (!hasProfileInfo)
        return {
          ...DEFAULT_USER_RULES_OUTPUT,
          beAbleToLeaveSettingsPage: false,
          hangingMessageText:
            'Before proceeding, you must first complete all required information in your Settings.',
          hangingMessageType: 'warning',
        };
      break;
  }

  return DEFAULT_USER_RULES_OUTPUT;
};

export const mapUserDataToUserRuleInput = <
  TRole extends UserRole,
  TApprovalStatus extends 'ACTIVE' | 'AWAITING'
>({
  role,
  clientUserPermission,
  clientId,
  approvedAt,
  approvalStatus,
  termsAcceptedAt,
  firstName,
  lastName,
  residence,
  email,
  photoHighUrl,
  photoLowUrl,
  photoOriginalUrl,
  attributes,
}: {
  role?: TRole;
  clientUserPermission?: ClientUserPermission | null;
  clientId?: string;
  approvedAt?: Date;
  approvalStatus?: TApprovalStatus;
  termsAcceptedAt?: Date;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  residence?: string | null;
  photoOriginalUrl?: string | null;
  photoHighUrl?: string | null;
  photoLowUrl?: string | null;
  attributes?: Array<{
    attributeId?: string | null;
    values?: string[] | null;
  }> | null;
}) => {
  const formalizedRole = ((): FormalizedUserRole | undefined => {
    if (!role) return undefined;
    // https://oakslab.atlassian.net/wiki/spaces/VET/pages/2645524491/User+Requirements+and+Actions+solution#User-Roles
    if (role === 'VETTER') return FormalizedUserRole.VETTER;
    if (role === 'ADMIN' && !clientId) return FormalizedUserRole.VETTER;
    if (role === 'CLIENT' && clientUserPermission === 'INTERVIEW')
      return FormalizedUserRole.INTERVIEWER;
    if (role === 'ADMIN' && clientId) return FormalizedUserRole.INTERVIEWER;
    if (role === 'CLIENT' && clientUserPermission === 'MANAGE')
      return FormalizedUserRole.MANAGER;

    return FormalizedUserRole.ADMIN;
  })();

  const isApprovedByAdmin = !!approvedAt || approvalStatus === 'ACTIVE';

  const acceptedTerms = !!termsAcceptedAt;

  const hasProfileInfo =
    !!firstName && !!lastName && !!email && (role !== 'VETTER' || !!residence);

  const hasProfilePicture =
    !!photoHighUrl || !!photoLowUrl || !!photoOriginalUrl;

  const hasAttributes = Object.values(userAttribute.MandatoryAttributes).every(
    id =>
      !!attributes?.some(
        attribute => attribute.attributeId === id && attribute.values?.length
      )
  );

  return {
    role: formalizedRole,
    acceptedTerms,
    hasProfileInfo,
    hasProfilePicture,
    hasAttributes,
    isApprovedByAdmin,
  };
};
