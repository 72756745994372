import { setContext } from '@apollo/client/link/context';
import { auth } from '../firebase';

export default setContext(async (_, { headers }) => {
  const token = await auth.currentUser?.getIdToken();
  return {
    headers: {
      ...headers,
      ...(token && { authorization: `Bearer ${token}` }),
    },
  };
});
