type Redirect = (route: string) => string | false;

export const loggedInRedirects: Redirect[] = [
  route =>
    ['/login'].includes(route) &&
    !['/invitation', '/404'].includes(route) &&
    '/dashboard',
];

export const loggedOutRedirects: Redirect[] = [
  route => !['/login', '/invitation', '/404'].includes(route) && '/login',
];
