import React from 'react';

import * as S from './styles';

type Props = {
  error: Error;
};

export const ErrorFallback: React.FC<Props> = ({ error }) => {
  return (
    <S.Wrap>
      <S.Headline>An unexpected error occured</S.Headline>
      <div>{error.message}</div>
      <div>{error.stack}</div>
    </S.Wrap>
  );
};
