import { getApolloClient } from '../apollo';
import {
  GrowthBookFeaturesQuery,
  GrowthBookFeaturesDocument,
} from '@vette/data-access';

const LOCAL_STORAGE_KEY = 'growthbook-features';
const EXPIRES_IN = 1000 * 60; // 1 minutes

export class GrowthBookCache {
  private featuresCache: {
    expiresAt: Date;
    features: GrowthBookFeaturesQuery['growthbookFeatures'];
  } | null = null;
  private subscribers = new Set<
    (features: GrowthBookFeaturesQuery['growthbookFeatures']) => void
  >();

  async fetchFeatures() {
    const client = getApolloClient();
    const { data } = await client.query<GrowthBookFeaturesQuery>({
      query: GrowthBookFeaturesDocument,
      fetchPolicy: 'network-only',
    });

    this.featuresCache = {
      expiresAt: new Date(Date.now() + EXPIRES_IN),
      features: data.growthbookFeatures,
    };

    if (typeof window !== 'undefined') {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(this.featuresCache)
      );
    }

    this.subscribeToExpiry();
    this.subscribers.forEach(s => s(data.growthbookFeatures));

    return data;
  }

  async loadFeatures() {
    if (this.featuresCache) {
      return this.featuresCache.features;
    }

    let cached;
    if (typeof window !== 'undefined') {
      cached = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    }

    let features;
    if (!cached) {
      features = await this.fetchFeatures();
    }

    this.featuresCache = cached ? JSON.parse(cached) : features;

    if (this.featuresCache?.expiresAt) {
      this.featuresCache.expiresAt = new Date(this.featuresCache.expiresAt);
      this.subscribeToExpiry();
    }

    return this.featuresCache?.features;
  }

  subscribe(
    subscriber: (
      features: GrowthBookFeaturesQuery['growthbookFeatures']
    ) => void
  ) {
    this.subscribers.add(subscriber);
    return () => {
      this.subscribers.delete(subscriber);
    };
  }

  private subscribeToExpiry() {
    if (this.featuresCache?.expiresAt) {
      const expireTime = this.featuresCache.expiresAt.getTime();
      setTimeout(() => {
        if (this.featuresCache && expireTime < Date.now()) {
          this.featuresCache = null;
          if (typeof window !== 'undefined') {
            window.localStorage.removeItem(LOCAL_STORAGE_KEY);
          }
          this.loadFeatures();
        }
      }, expireTime - Date.now());
    }
  }
}
