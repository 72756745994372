import React, { useCallback, useState } from 'react';
import { Title, Paragraph } from '../Typography';
import { Button } from '../Button';

import * as S from './styles';

type Props = {
  text: string | React.ReactNode;
  title?: string | React.ReactNode;
  okText?: string;
  cancelText?: string;
  hideOk?: boolean;
  onOk?: () => void | unknown | Promise<unknown>;
  onCancel?: () => void;
  cancelProps?: React.ComponentProps<typeof Button>;
  visible?: boolean;
  cancelAfterOk?: boolean;
  alignText?: 'center' | 'right' | 'left' | undefined;
  reverseButtons?: boolean;
  reverseButtonsColor?: boolean;
  textSize?: number;
  textWeight?: number;
};

export const WarningModal: React.FC<Props> = ({
  title,
  text,
  visible,
  okText,
  cancelText,
  hideOk,
  onCancel,
  onOk,
  cancelProps,
  cancelAfterOk = true,
  alignText,
  reverseButtons,
  reverseButtonsColor,
  textSize,
  textWeight,
}) => {
  const [okLoading, setOkLoading] = useState(false);

  const handleOk = useCallback(async () => {
    const result = onOk?.();
    setOkLoading(true);
    await result;
    setOkLoading(false);

    if (cancelAfterOk) {
      onCancel?.();
    }
  }, [onOk, setOkLoading, cancelAfterOk, onCancel]);

  return (
    <S.Modal
      width={title ? 800 : 600}
      visible={visible}
      footer={[
        ...(!hideOk
          ? [
              <Button
                key="content-1"
                $flex
                actionType={!reverseButtonsColor ? 'primary' : 'secondary'}
                onClick={handleOk}
                loading={okLoading}
                aria-label={okText || 'Yes'}
              >
                {okText || 'Yes'}
              </Button>,
            ]
          : []),
        <Button
          key="content-2"
          $flex
          actionType={hideOk || reverseButtonsColor ? 'primary' : 'secondary'}
          onClick={onCancel}
          aria-label={cancelText || 'No'}
          {...cancelProps}
        >
          {cancelText || 'No'}
        </Button>,
      ]}
      centered
      maskClosable
      onCancel={onCancel}
      $reverseButtons={reverseButtons}
    >
      {title && (
        <Title $size={36} $align="center" $marginBottom={30} level={2}>
          {title}
        </Title>
      )}
      {title && text && (
        <Paragraph $size={textSize || 18} $align="center" $weight={textWeight}>
          {text}
        </Paragraph>
      )}
      {!title && (
        <Title $size={24} $align={alignText || 'left'}>
          {text}
        </Title>
      )}
    </S.Modal>
  );
};
