module.exports = {
  // Colors:
  '@primary-color': '#FFD333',
  '@primary-color-hover': '@primary-color',
  '@primary-color-active': '#FFC700',

  '@link-color': '#6E6CC4',
  '@text-color': '#262626',
  '@disabled-color': 'rgba(0, 0, 0, .25)',
  '@text-color-secondary': '#A8A8A8',

  // Button
  '@btn-font-weight': 600,
  '@btn-default-bg': '#FFFFFF',
  '@btn-primary-color': '#000000',
  '@btn-default-color': '#000000',
  '@btn-ghost-color': '@primary-color',
  '@btn-ghost-bg': 'transparent',
  '@btn-text-bg': 'transparent',

  // Input
  '@form-error-input-bg': '#FDD3D5',
  '@input-height-lg': '48px',

  // Select
  '@select-single-item-height-lg': '48px',
  '@select-multiple-item-height-lg': '48px',

  // Checkbox
  '@checkbox-check-color': '#000000',
  '@checkbox-check-bg': '#FFFFFF',
  '@checkbox-border-radius': '0',

  //Switch
  '@switch-color': '#6DBE59',

  // Typography:
  '@font-family':
    "Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  '@font-size-base': '13px',
  '@typography-title-margin-top': '0',
  '@typography-title-margin-bottom': ' 0',

  '@heading-color': '#000000',

  '@heading-1-size': '36px',
  '@heading-2-size': '24px',
  '@heading-3-size': '18px',
  '@heading-4-size': '15px',
  '@heading-5-size': '12px',
  '@heading-6-size': '9px',

  // Borders:
  '@border-radius-base': '12px',
  '@border-color-base': 'rgba(0,0,0,0.15)',

  // Forms:
  '@form-item-margin-bottom': '12px',

  // Layout
  '@layout-header-height': '64px',
  '@layout-header-background': 'transparent',
  '@layout-sider-background': '#FFFFFF',

  'ant-radio': {
    display: 'none',
  },
  // Card
  '@card-head-padding': '0',
  '@card-padding-base': '0',

  // Tabs
  '@tabs-bar-margin': '0',
  '@tabs-title-font-size-lg': '24px',
  '@tabs-hover-color': '#161616',
  '@tabs-active-color': '#161616',
};
