import React, { useCallback, useEffect, useMemo } from 'react';
import * as S from './styles';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import {
  Frame,
  FrameSkeleton,
  Space,
  Text,
  TimerCountdown,
} from '../../common';
import {
  useAcceptVetterSessionMutation,
  useRejectVetterSessionMutation,
  useSessionAttributesQuery,
} from '@vette/data-access';
import { message } from '@vette/frontend-utils';
import { useAudio, usePrevious } from 'react-use';
import { useRouter } from 'next/router';
import { useFeatureValue } from '@growthbook/growthbook-react';
import {
  GrowthBookFeatures,
  defaultInterviewScriptFormFeatureValue,
} from '@vette/common-utils';

export const AcceptSessionModal: React.FC<{
  visible: boolean;
  timeout?: number;
  sessionId: string;
}> = ({ visible, timeout, sessionId }) => {
  const [acceptVetterSession] = useAcceptVetterSessionMutation();
  const [rejectVetterSession] = useRejectVetterSessionMutation();
  const router = useRouter();

  const showGeneralError = () => {
    message.error(
      'Something went wrong. Our team was notified and is intensively working on fixing the issue.'
    );
  };

  const prevVisibility = usePrevious(visible);
  const [audio, , controls] = useAudio({
    src: '/sounds/new_session.wav',
    loop: true,
  });

  useEffect(() => {
    if (visible && !prevVisibility) {
      controls.seek(0);
      controls.play();
    }

    if (!visible) {
      controls.pause();
    }
  }, [controls, visible, prevVisibility]);

  const onAccept = useCallback(async () => {
    const response = await acceptVetterSession({
      variables: {
        sessionId,
      },
    });

    if (
      response.data?.acceptVetterSession?.__typename === 'ValidationError' ||
      response.data?.acceptVetterSession?.__typename === 'NotFoundError'
    ) {
      showGeneralError();
    } else {
      await router.replace(`/interview/${sessionId}`);
    }
  }, [sessionId, acceptVetterSession, router]);

  const onReject = useCallback(async () => {
    const response = await rejectVetterSession({
      variables: {
        sessionId,
      },
    });

    if (
      response.data?.rejectVetterSession?.__typename === 'ValidationError' ||
      response.data?.rejectVetterSession?.__typename === 'NotFoundError'
    ) {
      showGeneralError();
    }
  }, [sessionId, rejectVetterSession]);
  ''.replace(/\//g, ' / ');

  const { data, loading } = useSessionAttributesQuery({
    variables: {
      sessionId,
    },
  });

  const session = data?.session;

  const interviewFeature = useFeatureValue(
    GrowthBookFeatures.interview_script_form,
    defaultInterviewScriptFormFeatureValue
  );

  const frameElements = useMemo(
    () =>
      session?.__typename === 'Session'
        ? [
            ...(interviewFeature.acceptRequestModal?.displayJob
              ? [{ title: 'Job', content: session.jobOffer.title }]
              : []),

            ...(session?.attributes?.map(attribute => ({
              title: attribute.name ?? '-',
              content: attribute.values?.join(', ') ?? '-',
            })) ?? []),
          ]
        : [],
    [
      interviewFeature.acceptRequestModal?.displayJob,
      session?.__typename === 'Session' && session?.attributes,
      session?.__typename === 'Session' && session?.jobOffer?.title,
    ]
  );

  const timeRemainingElement = (
    <S.FullWidthRow justify="center">
      <Space direction="horizontal" size={12}>
        <Text $size={18}>Time Remaining</Text>
        <Text $size={18} $weight={600}>
          <TimerCountdown targetTimestamp={timeout} format="00:00" />
        </Text>
      </Space>
    </S.FullWidthRow>
  );

  return (
    <>
      {audio}
      <S.Modal
        visible={visible}
        centered
        closable={false}
        destroyOnClose={true}
        width={700}
        footer={
          <Space $fullWidth direction="vertical" size={24}>
            <S.FullWidthRow justify="center">
              <S.RejectButton
                aria-label="Reject Vette"
                size="large"
                key="rejectButton"
                onClick={onReject}
                icon={
                  <S.ButtonIconWrapper>
                    <CallEndIcon />
                  </S.ButtonIconWrapper>
                }
              >
                Reject
              </S.RejectButton>
              <S.AcceptButton
                aria-label="Accept Vette"
                size="large"
                key="acceptButton"
                onClick={onAccept}
                icon={
                  <S.ButtonIconWrapper>
                    <CallIcon />
                  </S.ButtonIconWrapper>
                }
              >
                Accept
              </S.AcceptButton>
            </S.FullWidthRow>
            {frameElements.length > 0 && timeRemainingElement}
          </Space>
        }
        title="You have an incoming Vette"
      >
        {loading ? (
          <FrameSkeleton />
        ) : session?.__typename !== 'Session' || frameElements.length === 0 ? (
          timeRemainingElement
        ) : (
          <Frame elements={frameElements} />
        )}
      </S.Modal>
    </>
  );
};
