import { Row } from 'antd';
import { Button as BaseButton, Modal as BaseModal } from '../../common/atoms';
import styled from 'styled-components';

export const Modal = styled(BaseModal)``;

export const CountdownTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const ButtonIconWrapper = styled.div`
  display: flex;
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  padding: 4px;
  margin-right: 6px;
`;

export const BaseCommonButton = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.white};
  flex: 1;

  & > div > svg {
    width: 12px;
    height: 12px;
  }

  &:hover,
  &.ant-btn:not([disabled]):hover {
    border: none;
    color: ${({ theme }) => theme.colors.white};
  }

  &:active,
  &:focus {
    border: none;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const AcceptButton = styled(BaseCommonButton)`
  background-color: ${({ theme }) => theme.colors.green40};

  &:hover,
  &.ant-btn:not([disabled]):hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)),
      ${({ theme }) => theme.colors.green40};
  }

  &:active,
  &:focus {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
      ${({ theme }) => theme.colors.green40};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const RejectButton = styled(BaseCommonButton)`
  background-color: ${({ theme }) => theme.colors.red40};

  &:hover,
  &.ant-btn:not([disabled]):hover {
    color: ${({ theme }) => theme.colors.white};
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)),
      ${({ theme }) => theme.colors.red40};
  }

  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.white};
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
      ${({ theme }) => theme.colors.red40};
  }
`;

export const FullWidthRow = styled(Row)`
  width: 100%;
`;
